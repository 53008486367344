import {css} from '@emotion/core';
import {Col, Row, Form} from 'antd';
import React, {FC, memo} from 'react';
import {Redirect} from 'react-router-dom';
import {useQuote} from '../../contexts/Quote';
import {useUser, useSession} from '../../contexts/Session';
import ShipmentValue from './ShipmentValue';
import ShipmentForm from './ShipmentForm';
import {useAsync} from 'react-async-hook';
import {WakandaService} from '../../util/fetch-wakanda';
import {Adresse} from '../Orders/data';
import {getDefaultAddress} from '../Quote/patient/ShippingAddressSelect';

const Shipment: FC = () => {
  const user = useUser();
  const session = useSession();
  const [quote, setQuote] = useQuote();

  const addresses = useAsync(
    async (session: WakandaService) =>
      (session &&
        (await session.fetch<Adresse[]>('Clients', 'getAdressesLivraison'))) ||
      [],
    [session],
  );

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const {shippingAddress} = quote;
  const defaultAddress = getDefaultAddress(addresses.result);

  const address = shippingAddress || defaultAddress;

  const WrappedShipmentForm = Form.create({name: 'shipmentForm'})((props) => (
    <ShipmentForm
      {...props}
      user={user}
      quote={quote}
      onSubmit={(data) => setQuote({...quote, ...data})}
    />
  ));

  if (!address) {
    return <div />;
  }

  return (
    <div>
      <h3>Livraison</h3>
      <Row type="flex" gutter={20}>
        <Col
          css={css`
            margin-top: 10px;
            p {
              margin: 0;
            }
          `}
        >
          <ShipmentValue address={address} />
        </Col>
        <Col css={{maxWidth: '100%'}}>
          <WrappedShipmentForm />
        </Col>
      </Row>
    </div>
  );
};

export default memo(Shipment);
