import React, {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  DeporteBrand,
  useCurrentBrand,
  useCurrentCategory,
  useCurrentSelectedOptions,
} from '../../contexts/Quote';
import ProductSelection from './ProductSelection';

const Brands: FC<{parentPath: string}> = ({parentPath}) => {
  const [category] = useCurrentCategory();
  const [brand, setBrand] = useCurrentBrand();
  const [_, setSelectedOptions] = useCurrentSelectedOptions();
  const history = useHistory();

  if (!category) {
    return null;
  }

  const onSelect = (_brand: DeporteBrand): void => {
    if (_brand !== brand) {
      setBrand(_brand);
      setSelectedOptions([]);
    }

    history.push(`${parentPath}/options`);
  };

  if (!category.brands) {
    return (
      <p>Séléction de marque indisponible pour la catégorie {category.name}</p>
    );
  }

  return (
    <ProductSelection
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      products={category.brands}
      onSelect={onSelect}
      value={brand || undefined}
    />
  );
};

export default memo(Brands);
