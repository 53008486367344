import {Button, Col, Row, Tooltip} from 'antd';
import React, {FC, memo} from 'react';
import {
  getBaseQuoteOptions,
  TransferMethod,
  useQuote,
  useTerms,
  useTransfer,
} from '../../contexts/Quote';
import {useUser} from '../../contexts/Session';
import ConfirmationButton from '../../util/ConfirmationButton';
import {UserType} from '../../util/fetch-wakanda';

interface Props {
  loading: boolean;
  onFinalize: () => void;
}

const ValidationErrorsTooltip: FC<{errors?: string[]}> = ({errors = []}) =>
  errors.length ? (
    errors.length === 1 ? (
      <div>{errors?.[0]}</div>
    ) : (
      <div>
        <p>
          Vous devez satisfaire les conditions suivantes avant de finaliser
          votre commande :
        </p>
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    )
  ) : null;

const Buttons: FC<Props> = ({loading, onFinalize}) => {
  const user = useUser();
  const [quote, setQuote] = useQuote();
  const [{method, prints}] = useTransfer();
  /*const [lines, setLines] = useLines();
  const [currentLine, setCurrentLine] = useCurrentLine();*/
  const [terms] = useTerms();
  // const history = useHistory();

  if (!user) {
    return null;
  }

  /* const addNewLine = (): void => {
    const newLine: BaseQuoteLine = {options: getBaseQuoteLineOptions()};
    const newLines: BaseQuoteLine[] = [...lines, newLine];
    newLines[lines.indexOf(currentLine)] = {...currentLine};

    setLines(newLines);
    setCurrentLine(newLine);

    history.push('/quote/categories');
  }; */

  const validationErrors = [
    user.type === UserType.CLIENT &&
    method === TransferMethod.UPLOAD &&
    !prints.length
      ? "Veuillez ajouter vos fichiers d'empreintes, ou sélectionner une autre méthode de transfert"
      : '',
    user.type === UserType.CLIENT && !terms
      ? "Veuillez lire et accepter les conditions d'utilisation"
      : '',
    quote.lines.find(({articles}) => !articles?.length)
      ? 'Aucun article ne correspond à votre sélection, merci de contacter le support'
      : '',
    quote.lines?.find(({valid}) => valid !== true)
      ? 'Votre sélection est incomplète, veuillez retourner sur la page précédente et ajouter les options obligatoires manquantes'
      : '',
    !quote.shippingDate || quote.shippingDate === '0000-00-00'
      ? 'Veuillez entrer une date de livraison souhaitée'
      : '',
  ].filter((error) => !!error);

  return (
    <Row type="flex" justify="space-between" gutter={50}>
      <Col>
        <ConfirmationButton
          callback={() => setQuote(getBaseQuoteOptions())}
          confirmationText={
            <p>
              Êtes-vous sûr(e) de vouloir annuler cette commande ?<br />
              Les données saisies seront perdues.
            </p>
          }
          confirmationButton="Oui, annuler"
          type="danger"
          size="large"
        >
          Annuler
        </ConfirmationButton>
      </Col>

      <Col>
        <Tooltip
          title={
            validationErrors.length ? (
              <ValidationErrorsTooltip errors={validationErrors} />
            ) : null
          }
        >
          <Button
            disabled={!!validationErrors.length}
            type="primary"
            size="large"
            loading={loading}
            onClick={onFinalize}
          >
            Finaliser
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
};

export default memo(Buttons);
