import {Button, Col, Modal, Result, Row} from 'antd';
import React, {FC, Fragment, memo, ReactNode, useEffect, useState} from 'react';
import {Link, NavLink, Redirect} from 'react-router-dom';
import {getBaseQuoteOptions, useLines, useQuote} from '../../contexts/Quote';
import {useSession, useUser} from '../../contexts/Session';
import {User, UserType} from '../../util/fetch-wakanda';
import {cssUtils} from '../../util/render';
import {Order} from '../Orders/data';
import Buttons from './Buttons';
import Cart from './Cart';
import Comment from './Comment';
import DeliveryDate from './DeliveryDate';
import DocumentModal from './DocumentModal';
import Patient from './Patient';
import Prints from './Prints';
import Shipment from './Shipment';
import StickerModal from './StickerModal';
import TechnicalModal from './TechnicalModal';
import Terms from './Terms';
import {finalize, FinalizeError, getArticles, isFinalizeError} from './util';

const Summary: FC = () => {
  const [quote, setQuote] = useQuote();
  const [lines, setLines] = useLines();
  const user = useUser();
  const session = useSession();

  const [modalVisible, setModalVisible] = useState(false);
  const [modalSuccessful, setModalSuccessful] = useState(true);
  const [modalError, setModalError] = useState<FinalizeError | null>(null);
  const [loading, setLoading] = useState(false);

  const [order, setOrder] = useState<Order>();

  const fabricationDelay = lines
    // only the first line?
    .slice(0, 1)
    .reduce(
      (sum, {articles}) =>
        sum +
        (articles || []).reduce(
          (articlesSum, {delaiFabAM, delaiFabPM, selected}) =>
            articlesSum +
            ((new Date().getHours() < 12 ? delaiFabAM : delaiFabPM) || 0) *
              Math.min(1, (selected || []).filter((ear) => ear).length),
          0,
        ),
      0,
    );

  const transporterDelay = quote.transporter?.delai || 0;
  const delay = Math.max(1, transporterDelay + fabricationDelay - 2);

  console.log({fabricationDelay, transporterDelay, delay});

  const prepareLines = async (usr: User): Promise<void> => {
    await Promise.all(
      lines.map(async (line) => {
        await getArticles(quote, line, session, usr);
      }),
    );

    setLines(lines);
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    (async () => {
      await prepareLines(user);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, quote.transfer.method]);

  const onFinalize = async (): Promise<void> => {
    if (!user) {
      return;
    }

    setLoading(true);
    const result = await finalize(session, quote, lines, user);

    if (!isFinalizeError(result)) {
      setOrder(result);
      setModalSuccessful(true);
      setModalVisible(true);
      setModalError(null);
    } else {
      setModalSuccessful(false);
      setModalVisible(true);
      setModalError(result);
    }

    setLoading(false);
  };

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const emptyQuote = (): void => {
    setQuote(getBaseQuoteOptions());
  };

  const orderButtons: ReactNode[] = [];
  if (order) {
    if (user.type !== UserType.CLIENT) {
      orderButtons.push(<StickerModal key={0} order={order} large={true} />);
      orderButtons.push(<TechnicalModal key={1} order={order} large={true} />);
    } else {
      orderButtons.push(<DocumentModal key={0} order={order} large={true} />);
    }
  }

  return (
    <Fragment>
      <Row type="flex" justify="center" css={cssUtils.heightP()}>
        <Col span={20} xl={16}>
          <h1 css={{marginBottom: 20}}>Panier</h1>

          <Patient patientOnly={true} /> {/* user.type === UserType.CLIENT */}
          <Cart css={{margin: '10px 0'}} />
          <Row css={{margin: '10px 0'}}>
            <Col span={user.type === UserType.CLIENT ? 8 : 24}>
              <Shipment />
            </Col>
            {user.type === UserType.CLIENT ? (
              <Col span={16}>
                <Prints />
                <DeliveryDate delay={delay} />
              </Col>
            ) : null}
          </Row>
          <Comment />
          {user?.type === UserType.CLIENT ? <Terms /> : null}
          <Buttons loading={loading} onFinalize={onFinalize} />
        </Col>
      </Row>
      <Modal
        title={
          modalSuccessful
            ? 'Commande réussie'
            : 'Mise en ligne de la commande a échoué'
        }
        visible={modalVisible}
        closable={false}
        footer={null}
        width={720}
      >
        <Result
          status={modalSuccessful ? 'success' : 'error'}
          title={
            modalSuccessful
              ? 'Commande validée !'
              : 'Erreur lors de la mise en ligne de votre commande'
          }
          subTitle={
            modalSuccessful ? (
              <div css={{maxWidth: 500, margin: 'auto'}}>
                <p>
                  Merci d&apos;avoir passé votre commande.
                  <br />
                  Vous recevrez un e-mail de confirmation dès que la commande
                  sera prise en compte par nos services.
                  <br />
                  Vous pouvez encore modifier votre commande dans l’onglet&nbsp;
                  <NavLink to="/orders">Suivi de commande</NavLink>
                </p>
              </div>
            ) : (
              <div css={{maxWidth: 500, margin: 'auto'}}>
                {modalError?.error === 'attachments' ? (
                  <p>
                    La mise en ligne de vos emprientes numérique a échoué. Merci
                    de réessayer plus tard. Si cette erreur persiste, veuillez
                    contacter le support.
                  </p>
                ) : (
                  <p>
                    Une erreur inconnue est survenue. Merci de réessayer plus
                    tard. Si cette erreur persiste, veuillez contacter le
                    support.
                  </p>
                )}
              </div>
            )
          }
          extra={
            modalSuccessful ? (
              <Fragment>
                {orderButtons}
                <Link to="/orders">
                  <Button
                    type="primary"
                    onClick={emptyQuote}
                    style={{marginLeft: 8}}
                  >
                    Suivi des commandes
                  </Button>
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  type="primary"
                  onClick={() => setModalVisible(false)}
                  style={{marginLeft: 8}}
                >
                  OK
                </Button>
              </Fragment>
            )
          }
        />
      </Modal>
    </Fragment>
  );
};

export default memo(Summary);
