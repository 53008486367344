import {css} from '@emotion/core';
import {Checkbox, Col, Radio, Row, Select, Input} from 'antd';
import React, {FC, ReactNode} from 'react';
import {
  TBoolean,
  UndefinedTuple,
  useCurrentEars,
  useCurrentLockedEars,
} from '../../contexts/Quote';
import {
  colors,
  cssCursorPointer,
  cssDisabledLine,
  cssOption,
  DisabledEar,
} from '../../util/render';
import {
  CheckFunction,
  getSelectedEars,
  OPTION_GROUP_CHECK_TYPE,
  SelectableOption,
  SELECTED_EARS,
} from './static';
import {COLOR, colorLabels, colorHex} from './data';
import {readableColor} from 'polished';

const {Option} = Select;

const cssNoMarginRight = css`
  margin-right: 0;
`;

const cssOptionSelector = css`
  &[data-check-type='EVENT_INPUT'],
  &[data-check-type='TUBE_COLOR_INPUT'] {
    .checked {
      background-color: ${colors.primary};
    }
    .input-container {
      padding: 6px;

      .ant-select-selection-selected-value {
        width: 100%;
      }
    }
  }
  &[data-check-type='MARK_INPUT'] {
    .input-container {
      padding: 6px;
    }
  }

  .option-help {
    display: block;
    font-size: 10px;
    cursor: help;
    font-style: italic;
  }
`;

interface Props extends SelectableOption {
  checkType: OPTION_GROUP_CHECK_TYPE;
  onCheck: CheckFunction;
  minimumEventRange?: [number, number];
  coloredTubeInputValues?: [COLOR[], COLOR[]];
  isPair?: true;
}

// [0.8, 0.9, ..., 3]
// min={0.8} max={3} step={0.1}
const EVENT_INPUT_VALUES = Array(26)
  .fill(0)
  .map((_, index) => (index + 5) / 10);

const SELECT_INPUT_TYPES = [
  OPTION_GROUP_CHECK_TYPE.EVENT_INPUT,
  OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT,
  OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
];

export const OptionSelector: FC<Props> = ({
  checkType,
  type,
  name,
  label,
  style,
  disabled,
  checked,
  value,
  onCheck,
  minimumEventRange,
  coloredTubeInputValues,
  isPair,
}) => {
  const [ears] = useCurrentEars();
  const [lockedEars] = useCurrentLockedEars();

  const [selectedEars, disabledEars] = [ears, disabled].map(getSelectedEars);
  const cssOptionArray = [cssCursorPointer, cssOption];

  const getComponent = (
    checkType: OPTION_GROUP_CHECK_TYPE,
    index: number,
  ): ReactNode => {
    switch (checkType) {
      case OPTION_GROUP_CHECK_TYPE.CHECKBOX:
        return <Checkbox disabled={disabled[index]} checked={checked[index]} />;
      case OPTION_GROUP_CHECK_TYPE.RADIO:
        return (
          <Radio
            css={cssNoMarginRight}
            disabled={disabled[index]}
            checked={checked[index]}
          />
        );
      case OPTION_GROUP_CHECK_TYPE.MARK_INPUT:
        return (
          <Input
            disabled={disabled[index]}
            style={{width: '100%', textAlign: 'center'}}
            value={value?.[index]}
            maxLength={6}
            onChange={(event) =>
              onCheck(
                type,
                name,
                label,
                setIndexChecked(index, true),
                setIndexValue(index, event.target.value),
              )
            }
          />
        );
      case OPTION_GROUP_CHECK_TYPE.EVENT_INPUT:
        const minValue = minimumEventRange?.[index];
        if (
          minValue &&
          (value?.[index] || 0) > 0 &&
          (value?.[index] || 0) < minValue
        ) {
          onCheck(
            type,
            name,
            label,
            setIndexChecked(index, true),
            setIndexValue(index, minValue),
          );
        }

        return (
          <Select<number>
            showSearch
            disabled={disabled[index]}
            style={{width: '100%'}}
            optionFilterProp="children"
            value={value?.[index] as number | undefined}
            dropdownClassName="dropdown-mm"
            onSelect={(_value) =>
              onCheck(
                type,
                name,
                label,
                setIndexChecked(index, true),
                setIndexValue(index, _value),
              )
            }
            filterOption={(input, option) =>
              (option.props.children || '')
                .toString()
                .replace(/[,.]/g, '')
                .includes(input.toLowerCase())
            }
            showArrow={false}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{width: 100}}
          >
            {EVENT_INPUT_VALUES.map((val) => (
              <Option
                key={val}
                value={val}
                disabled={minValue ? val < minValue : false}
              >
                {val.toFixed(1).replace('.', ',')}
              </Option>
            ))}
          </Select>
        );
      case OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT:
        const colorValues: COLOR[] = coloredTubeInputValues?.[index] || [];
        if (value?.[index] && !colorValues.includes(value?.[index] as COLOR)) {
          onCheck(
            type,
            name,
            label,
            setIndexChecked(index, true),
            setIndexValue(index, colorValues[0]),
          );
        }

        return (
          <Select<COLOR>
            showSearch
            disabled={disabled[index]}
            style={{width: '100%'}}
            optionFilterProp="children"
            value={value?.[index] as COLOR}
            onSelect={(_value) =>
              onCheck(
                type,
                name,
                label,
                setIndexChecked(index, true),
                setIndexValue(index, _value),
              )
            }
            showArrow={false}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{width: 100}}
          >
            {(coloredTubeInputValues?.[index] || []).map((color) => (
              <Option
                key={color}
                value={color}
                style={{
                  backgroundColor: colorHex[color],
                  color: readableColor(colorHex[color]),
                }}
              >
                {colorLabels[color]}
              </Option>
            ))}
          </Select>
        );
      default:
        return null;
    }
  };

  const toggleBoth = (): TBoolean =>
    (checked[0] || disabled[0]) && (checked[1] || disabled[1])
      ? [false, false]
      : [!disabled[0] && !!ears[0], !disabled[1] && !!ears[1]];

  const toggleIndex = (index: number): TBoolean =>
    [0, 1].map((_index) =>
      index === _index
        ? !disabled[_index] && !checked[_index]
        : checked[_index],
    ) as TBoolean;

  const setIndexChecked = (index: number, _checked: boolean): TBoolean =>
    [0, 1].map((_index) =>
      index === _index ? !disabled[_index] && _checked : checked[_index],
    ) as TBoolean;

  const setIndexValue = (
    index: number,
    _value: number | string,
  ): UndefinedTuple<number | string> =>
    [0, 1].map((_index) =>
      index === _index ? _value : (value || [])[_index] || undefined,
    ) as UndefinedTuple<number | string>;

  const lockedMode =
    selectedEars === SELECTED_EARS.BOTH && (isPair || lockedEars);
  const displayedEars = lockedMode ? [ears[0]] : ears;

  return (
    <Row
      type="flex"
      justify="center"
      data-check-type={checkType}
      css={[
        cssOptionSelector,
        SELECT_INPUT_TYPES.includes(checkType) ? null : cssCursorPointer,
        disabledEars === SELECTED_EARS.BOTH ? cssDisabledLine : null,
      ]}
    >
      <Col
        span={18}
        css={[cssOption, disabledEars !== SELECTED_EARS.BOTH ? style : null]}
        onClick={() => {
          if (SELECT_INPUT_TYPES.includes(checkType)) {
            return;
          }
          onCheck(type, name, label, toggleBoth());
        }}
      >
        {label}
      </Col>
      {displayedEars.map((ear, index) => (
        <Col
          key={index}
          span={6 / displayedEars.length}
          className={[
            'input-container',
            checked[index] ? 'checked' : '',
            // TODO 'has-error',
          ]
            .filter((className) => !!className)
            .join(' ')}
          css={[
            cssOptionArray,
            !ear || disabled[index] ? cssDisabledLine : null,
          ]}
          onClick={(event) => {
            // prevents firing onCheck twice when clicking the outside of the radio
            event.preventDefault();
            if (SELECT_INPUT_TYPES.includes(checkType)) {
              return;
            }

            onCheck(
              type,
              name,
              label,
              lockedMode ? toggleBoth() : toggleIndex(index),
            );
          }}
        >
          {ear ? getComponent(checkType, index) : <DisabledEar />}
          {/* {lockedMode ? (
            <small
              className="option-help"
              title="Cette option est unique et s'appliquera aux deux oreilles"
            >
              Gauche et droite
            </small>
          ) : null} */}
        </Col>
      ))}
    </Row>
  );
};
