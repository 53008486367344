import {css} from '@emotion/core';
import React, {FC} from 'react';
import {Option, TBoolean, UndefinedTuple} from '../../contexts/Quote';
import {borderContainerCss} from '../../util/render';
import {EVENT, TYPE, DEPORTE_OPTION, TUBE, OPTION, COLOR} from './data';
import {OptionSelector} from './OptionSelector';
import {OPTION_GROUP_CHECK_TYPE, SelectableOption} from './static';

// prevents border overlap with left panel
export const withoutBorderLeftCss = css`
  position: relative;
  right: 1px;
`;

interface Props {
  options: SelectableOption[];
  checkType: OPTION_GROUP_CHECK_TYPE;
  setSelectedOption: (
    option: Option,
    checkType: OPTION_GROUP_CHECK_TYPE,
  ) => void;
}

export const OptionsGroup: FC<Props> = ({
  options,
  checkType,
  setSelectedOption,
}) => {
  if (!options) {
    return <div />;
  }

  const onCheck = (
    type: TYPE,
    name: string,
    label: string,
    selected: TBoolean,
    value?: UndefinedTuple<number | string>,
  ): void => {
    setSelectedOption({type, name, label, selected, value}, checkType);

    return undefined;
  };

  return (
    <div css={[borderContainerCss, withoutBorderLeftCss]}>
      {options.map((option, index) => {
        let optionCheckType = option.optionsValueMap?.type || checkType;
        const minimumEventRange: [number, number] = [0, 0];
        const coloredTubeInputValues: [COLOR[], COLOR[]] = [[], []];

        if (
          checkType === OPTION_GROUP_CHECK_TYPE.EVENT_INPUT &&
          option.name !== EVENT.CUSTOM
        ) {
          optionCheckType = OPTION_GROUP_CHECK_TYPE.RADIO;
        }
        if (
          checkType === OPTION_GROUP_CHECK_TYPE.MARK_INPUT &&
          option.name !== DEPORTE_OPTION.CUSTOM_MARK
        ) {
          optionCheckType = OPTION_GROUP_CHECK_TYPE.RADIO;
        }
        if (
          checkType === OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT &&
          option.name !== TUBE.COLORED
        ) {
          optionCheckType = OPTION_GROUP_CHECK_TYPE.RADIO;
        }

        if (option.name === EVENT.CUSTOM) {
          [0, 1].map(
            (earIndex) =>
              (minimumEventRange[earIndex] = options.find(
                ({name, checked}) =>
                  name === EVENT.POWER_VENT && checked[earIndex],
              )
                ? 0.9
                : 0),
          );
        } else if (option.name === TUBE.COLORED) {
          [0, 1].map(
            (earIndex) =>
              (coloredTubeInputValues[earIndex] = options.find(
                ({name, checked}) => name === TUBE.MAT && checked[earIndex],
              )
                ? [COLOR.BROWN]
                : [COLOR.ROSE, COLOR.BROWN]),
          );
        }

        return (
          <OptionSelector
            key={index}
            {...option}
            checkType={optionCheckType}
            minimumEventRange={minimumEventRange}
            coloredTubeInputValues={coloredTubeInputValues}
            isPair={option.optionsValueMap?.isPair}
            onCheck={onCheck}
          />
        );
      })}
    </div>
  );
};
