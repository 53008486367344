import {Button, Modal, Tooltip} from 'antd';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import React, {FC, Fragment, memo, useState} from 'react';
import {useSession} from '../../contexts/Session';
import {WakandaService} from '../../util/fetch-wakanda';
import {getMomentFromTimestamp} from '../../util/render';
import {
  EVENT,
  eventLabels,
  eventRules,
  MISCELLANEOUS,
  miscellaneousLabels,
  miscellaneousRules,
  OPTION,
  optionLabels,
  optionRules,
  optionTubeLabels,
  optionTubeRules,
  OPTION_TUBE,
  TUBE,
  tubeLabels,
  tubeRules,
  earWaxShieldRules,
  earWaxShieldLabels,
  optionEmboutLabelRules,
  deporteOptionsRules,
  DEPORTE_OPTION,
  deporteOptionLabels,
  EARWAX_SHIELD,
} from '../Options/data';
import {Client, Order} from '../Orders/data';
import {Article, getLabelFromRule} from './util';

export const loadLogo = (): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = '/logo_interson.png';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject('pcq');
    };
  });
};

export const loadQRCode = (text: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    QRCode.toDataURL(text, {errorCorrectionLevel: 'H'}).then((url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject('pcq');
      };
    });
  });
};

const generate = async (
  session: WakandaService,
  commande: Order,
): Promise<string> => {
  const client =
    (await session.fetch<Client>('Clients', 'getClient', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id_client: commande.id_client.__KEY,
    })) || null;

  const img = await loadLogo();
  let qr;
  if (commande.numeroBon) {
    qr = await loadQRCode(commande.numeroBon);
  }

  const doc = new jsPDF();

  if (!commande.ID) {
    doc.setFontSize(90);
    doc.setTextColor('#DDDDDD');
    doc.setFont('helvetica', 'normal');
    doc.text('Document temporaire', 30, 280, {angle: 57});
  }
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor('#000000');

  // logo
  doc.addImage(img, 'PNG', 10, 10, 30, 25);
  // qrcode
  /* if (commande.numeroBon) {
    doc.addImage(qr, 'PNG', 170, 260, 30, 30);
  } */

  // patient
  doc.setFont('helvetica', 'bold');
  doc.text('Patient : ' + commande.patient, 43, 17);

  // header top-right
  doc.setFontSize(12);
  doc.text('COMMANDE', 200, 15, {align: 'right'});
  doc.setFontSize(10);
  if (commande.numeroBon) {
    doc.text(`Réf. : ${commande.numeroBon}`, 200, 20, {align: 'right'});
  }
  if (client) {
    doc.text(`Code client : ${client.code}`, 200, 25, {align: 'right'});
  }

  doc.setFont('helvetica', 'normal');
  doc.text(
    `Date de commande : ${getMomentFromTimestamp(commande.dateSaisie).format(
      'L',
    )}`,
    200,
    30,
    {align: 'right'},
  );
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(12);

  // client & delivery
  doc.setFontSize(14);
  doc.text('CLIENT', 13, 43);
  doc.setFontSize(10);
  doc.text(client?.code, 33, 43);
  doc.setFontSize(14);
  doc.text('ADRESSE DE LIVRAISON', 111, 43);
  doc.setFillColor('#F0F0F0');
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash([1]);
  doc.roundedRect(10, 45, 92, 35, 3, 3, 'FD');
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.roundedRect(108, 45, 92, 35, 3, 3);
  doc.setFontSize(10);
  doc.text(client?.raisonSociale || '', 12, 50);
  doc.text(commande.livr_raisonSociale || '', 110, 50);

  doc.setFont('helvetica', 'normal');
  doc.text(client?.adresseGenerale || '', 12, 60);
  doc.text(commande.livr_adresse || '', 110, 60);
  doc.text(`${client?.codePostal || ''} ${client?.ville || ''}`, 12, 65);
  doc.text(`${commande.livr_codePostal} ${commande.livr_ville}`, 110, 65);
  doc.setFontSize(9);
  doc.text(`E-mail : ${client?.email || ''}`, 12, 75);
  doc.text(`E-mail : ${commande.email}`, 110, 75);

  const labels = {
    categorie: 'Catégorie',
    produit: 'Produit',
    marque: 'Marque',
    ecouteur: 'Écouteur',
    bague: 'Bague',
    matiere: 'Matière',
    forme: 'Forme',
    formeEpaulement: 'Epaulement',
    couleur: 'Couleur',
    event: 'Event',
    eventDiam: 'Diamètre event',
    eventSurMesure: 'Diamètre event (sur mesure)',
    finition: 'Finition',
    tube: 'Tube',
    tubeOption: 'Tube option',
    optionEmbout: 'Options embout',
    repereCouleur: 'Repère de couleur',
    otoscan: 'OTOSCAN Otometrics',
    optionsEcouteur: 'Options écouteur',
    vernisAntibacterien: 'Vernis antibactérien',
    optionAquason: 'Option',
    optionEP2: 'Option',
    optionCordonEP2: 'Couleur cordon',
    optionGravureEP2: 'Gravure',
    optionPianissimo: 'Options',
    optionPasstopT: 'Options',
    optionTir: 'Option',
    sousGarantie: 'Sous garantie',
    tubeCouleur: 'Tube couleur',
    optionDeporte: 'Options',
  };

  const bagueIds: [string | null, string | null] = [null, null];
  const bagues: [Article | null, Article | null] = [null, null];
  // eslint-disable-next-line array-callback-return
  ['OG', 'OD'].map((ear, index) => {
    const detailsBague = commande.details?.[ear as 'OG' | 'OD']?.bague;
    if (detailsBague) {
      bagueIds[index] = detailsBague;
    }
  });

  const baguesArts =
    (await session.fetch<Article[]>('Articles', 'getArticlesFromIDs', {
      ids: Array.from(new Set(bagueIds)),
    })) || [];

  // eslint-disable-next-line array-callback-return
  bagueIds.map((id, index) => {
    const bagueArt = baguesArts.find(({ID}) => ID === id);
    if (bagueArt) {
      bagues[index] = bagueArt;
    }
  });

  const earDetails = async (
    ear: 'OG' | 'OD',
    doc: jsPDF,
    height: number,
  ): Promise<number> => {
    if (!commande.details) {
      return height;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const details = (commande.details as any)[ear];

    if (!details) {
      return height;
    }

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(10);
    doc.setTextColor('#000000');
    doc.text(ear, 11, height);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
    doc.text('1', 190, height, {align: 'center'});
    doc.setTextColor('#555555');
    height += 5;
    Object.entries(labels).map((entry): void => {
      const [key, label] = entry;
      if (!details[key]) {
        return undefined;
      }

      let value = '';

      switch (key) {
        case 'bague':
          if (bagues && bagues.length > 0) {
            value = bagues[ear === 'OG' ? 0 : 1]?.libelle || '';
          }
          break;
        case 'marque':
          if (bagues && bagues.length > 0) {
            value = commande.details[ear]?.deporteBrand || '';
          }
          break;
        case 'repereCouleur':
        case 'otoscan':
        case 'vernisAntibacterien':
        case 'sousGarantie':
        case 'optionGravureEP2':
          value = details[key] ? 'Oui' : 'Non';
          break;
        case 'tube':
          const tubeEntry = Object.entries(tubeRules).find((tubeRule) =>
            details[key].includes(tubeRule[1]),
          );
          if (tubeEntry) {
            value = tubeLabels[tubeEntry[0] as TUBE];
          }
          break;
        case 'optionEmbout': {
          const values: string[] = [];
          details[key].map((detail: string): void => {
            const optionTubeEntry = Object.entries(
              optionTubeRules,
            ).find((optionTubeRule) => detail.includes(optionTubeRule[1]));
            if (optionTubeEntry) {
              values.push(optionTubeLabels[optionTubeEntry[0] as OPTION_TUBE]);
              return undefined;
            }

            const optionEmboutEntry = Object.entries(
              optionEmboutLabelRules,
            ).find((optionTubeRule) =>
              detail.includes(optionTubeRule[1] || ''),
            );
            if (optionEmboutEntry) {
              values.push(optionLabels[optionEmboutEntry[0] as OPTION]);
            }

            return undefined;
          });
          value = values.join(', ');
          break;
        }
        case 'eventDiam':
        case 'eventSurMesure':
          value = `${details[key]} mm`;
          break;
        case 'event':
          const eventEntry = Object.entries(eventRules).find((eventRule) =>
            details[key].includes(eventRule[1]),
          );
          if (eventEntry) {
            value = eventLabels[eventEntry[0] as EVENT];
          }
          break;
        case 'optionsEcouteur': {
          const values: string[] = [];
          details[key].map((detail: string): void => {
            if (detail.includes('OPT_montage')) {
              values.push('Montage écouteur');
              return undefined;
            }

            const miscellaneousEntry = Object.entries(miscellaneousRules).find(
              (miscellaneousRule) =>
                miscellaneousRule[1] && detail.includes(miscellaneousRule[1]),
            );
            if (miscellaneousEntry) {
              values.push(
                miscellaneousLabels[miscellaneousEntry[0] as MISCELLANEOUS],
              );
              return undefined;
            }

            const earWaxEntry = Object.entries(earWaxShieldRules).find(
              (earWaxShieldRule) =>
                earWaxShieldRule[1] && detail.includes(earWaxShieldRule[1]),
            );
            if (earWaxEntry) {
              values.push(earWaxShieldLabels[earWaxEntry[0] as EARWAX_SHIELD]);
            }

            return undefined;
          });
          value = values.join(', ');
          break;
        }
        case 'optionDeporte': {
          const values: string[] = [];
          details[key].map((detail: string): void => {
            const optionDeporteEntry = Object.entries(deporteOptionsRules).find(
              (deporteOptionsRule) =>
                deporteOptionsRule[1] && detail.includes(deporteOptionsRule[1]),
            );
            if (optionDeporteEntry) {
              if (
                optionDeporteEntry[0] === DEPORTE_OPTION.LEFT_RIGHT_MARK &&
                details.customMark
              ) {
                values.push(`Autre marquage: "${details.customMark}"`);
              } else {
                const ruleLabel =
                  deporteOptionLabels[optionDeporteEntry[0] as DEPORTE_OPTION];
                if (ruleLabel) {
                  values.push(ruleLabel);
                }
              }
            }

            return undefined;
          });
          value = values.join(', ');
          break;
        }
        case 'optionAquason':
          if (details[key].includes('OPT_option01')) {
            value = 'Flotteur';
          }
          break;
        case 'optionEP2': {
          const optionEntry = Object.entries(optionRules).find(
            (optionRule) =>
              optionRule[1] && details[key].includes(optionRule[1]),
          );
          if (optionEntry) {
            value = optionLabels[optionEntry[0] as OPTION];
          }
          break;
        }
        /* case 'optionCordonEP2': {
          const cordColorEntry = Object.entries(cordColorRules).find(
            (cordColorRule) =>
              cordColorRule[1] && details[key].includes(cordColorRule[1]),
          );
          if (cordColorEntry) {
            value = cordColorLabels[cordColorEntry[0] as CORD_COLOR];
          }
          break;
        } */
        case 'optionPianissimo':
          value = details[key].join(', ');
          break;
        case 'optionPasstopT': {
          const values: string[] = [];
          details[key].map((detail: string): void => {
            const optionEntry = Object.entries(optionRules).find(
              (optionRule) => optionRule[1] && detail.includes(optionRule[1]),
            );
            if (optionEntry) {
              values.push(optionLabels[optionEntry[0] as OPTION]);
            }

            return undefined;
          });
          value = values.join(', ');
          break;
        }
        case 'optionTir':
          if (details[key].includes('OPT_option01')) {
            value = 'Camouflage';
          }
          break;
        default:
          value = details[key];
          break;
      }

      if (!value) {
        return undefined;
      }

      const text = `${label} : ${value}`;
      doc.text(text, 15, height);
      height += 5;

      return undefined;
    });
    return height + 5;
  };

  let height = 104;
  height = await earDetails('OG', doc, height);
  height = await earDetails('OD', doc, height);

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash();
  doc.rect(10, 85, 190, height - 85);
  doc.rect(180, 95, 20, height - 100);
  doc.setFillColor('#000000');
  doc.rect(10, 90, 190, 5, 'F');
  doc.rect(10, height - 5, 190, 5, 'F');
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(12);
  doc.setTextColor('#FFFFFF');
  doc.text('Désignation', 11, 94);
  doc.text('Qté', 190, 94, {align: 'center'});

  height += 5;

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(11);
  doc.setTextColor('#000000');

  if (commande.details.files?.length > 0) {
    doc.text(
      "Fichiers d'empreintes : " + commande.details.files.length,
      10,
      height,
    );
    height += 3;
  }

  //comments
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash([1]);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.roundedRect(10, height, 100, 30, 3, 3);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash();

  height += 5;

  doc.text('Commentaires', 46, height);

  doc.text(commande.messageInterne, 12, height + 5, {maxWidth: 90});

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(10);

  height += 3;

  /* doc.text('PROCÉDURE', 115, height);
  doc.text("1. J'imprime le bon de commande", 115, height + 5);
  doc.text('2. Je joins les empreintes physiques', 115, height + 10);
  doc.text("3. J'insère dans le colis remis au transporteur", 115, height + 15);*/

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(8);

  doc.text(
    "1, route d'Aubais - 30111 CONGENIES - Téléphone: 0466802289",
    105,
    285,
    {align: 'center'},
  );
  doc.text('www.interson-protac.com - audio@interson-protac.com', 105, 288, {
    align: 'center',
  });

  doc.setFont('helvetica', 'normal');

  return doc.output('bloburi');
};

interface Props {
  large?: boolean;
  disabled?: boolean;
  order: Partial<Order>;
}

const DocumentModal: FC<Props> = ({large, disabled, order}) => {
  const session = useSession();

  const [dataUri, setDataUri] = useState('');

  const hideModal = (): void => {
    setDataUri('');
  };

  const getPDF = async (): Promise<void> => {
    const dataUri = await generate(session, order as Order);

    setDataUri(dataUri);
  };

  return (
    <Fragment>
      <Tooltip
        title={!disabled ? 'Télécharger un export PDF de la commande' : null}
      >
        <Button
          disabled={disabled}
          shape={large ? undefined : 'circle'}
          icon="file-pdf"
          style={{margin: '0 8px'}}
          onClick={getPDF}
        >
          {large ? 'Imprimer Commande' : null}
        </Button>
      </Tooltip>
      <Modal
        width="90vw"
        visible={dataUri.length > 0}
        footer={[
          <a key="download" href={dataUri} download={`${order.patient}.pdf`}>
            <Button>Télécharger</Button>
          </a>,
          <Button
            key="close"
            type="primary"
            onClick={hideModal}
            style={{marginLeft: 8}}
          >
            Fermer
          </Button>,
        ]}
      >
        <div style={{height: '80vh'}}>
          {dataUri.length > 0 ? (
            <iframe
              title="PDF"
              src={`/pdf/web/viewer.html?file=${dataUri}`}
              width="100%"
              height="100%"
              style={{border: 0}}
            />
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default memo(DocumentModal);
