import React, {FC, memo, useState, Fragment} from 'react';
import jsPDF from 'jspdf';
import {Order, Client, Transporteur} from '../Orders/data';
import {WakandaService} from '../../util/fetch-wakanda';
import {useSession} from '../../contexts/Session';
import {Modal, Button, Tooltip} from 'antd';
import {Article, getLabelFromRule} from './util';
import {loadQRCode} from './DocumentModal';
import {
  eventRules,
  eventLabels,
  tubeRules,
  tubeLabels,
  optionTubeRules,
  optionTubeLabels,
  optionRules,
  optionLabels,
} from '../Options/data';
import moment from 'moment';

export const loadLogo = (): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = 'logo_interson.png';
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject('pcq');
    };
  });
};

const generate = async (
  session: WakandaService,
  commande: Order,
): Promise<string> => {
  const client =
    (await session.fetch<Client>('Clients', 'getClient', {
      // eslint-disable-next-line @typescript-eslint/camelcase
      id_client: commande.id_client.__KEY,
    })) || null;

  if (!client) {
    return '';
  }

  const transporteurs =
    (await session.fetch<Transporteur[]>('Transporteurs', 'getTRP')) || [];

  const transporteur = transporteurs.find(
    ({ID}) => ID === commande.id_transporteur.__KEY,
  );

  const img = await loadLogo();

  const doc = new jsPDF({format: 'a5'});

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.setTextColor('#000000');

  // logo
  doc.addImage(img, 'PNG', 10, 10, 20, 15);

  // title
  doc.setFont('helvetica', 'normal');
  doc.setFontSize(9);

  doc.text('N° OF :', 32, 15);
  doc.text('Patient :', 32, 20);
  doc.setFont('helvetica', 'bold');
  doc.text(commande.numeroBon, 45, 15);
  doc.text(commande.patient, 45, 20);

  doc.setFont('helvetica', 'normal');
  doc.text('Date de commande :', 75, 10);
  if (commande.noBoite) {
    doc.setFontSize(9);
    doc.text('Boite n° ', 75, 15);
    doc.setFont('helvetica', 'bold');
    doc.text(commande.noBoite || '', 87, 15);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
  }
  // doc.text('Reçu le :', 75, 20);
  doc.text('Date de départ :', 75, 25);
  doc.setFont('helvetica', 'bold');
  doc.text(moment(commande.dateSaisie).format('DD/MM/YYYY HH:mm'), 109, 10);
  // doc.text(commande.numeroBon, 140, 20);
  if (commande.dateSouhaitee) {
    doc.text(moment(commande.dateSouhaitee).format('DD/MM/YYYY'), 102, 25);
  } else {
    doc.text('Non définie', 102, 25);
  }

  {
    const maxWidth = 60;
    const heightOffset = 6;
    let height = 40 - heightOffset;
    // client & delivery
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('Point de vente :', 10, (height += heightOffset));
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(commande.livr_raisonSociale || '', 10, (height += heightOffset), {
      maxWidth,
    });
    doc.text(commande.livr_adresse || '', 10, (height += heightOffset), {
      maxWidth,
    });
    doc.text(
      `${commande.livr_codePostal} ${commande.livr_ville}`,
      10,
      (height += heightOffset),
      {maxWidth},
    );
    doc.text(`E-mail : ${commande.email}`, 10, (height += heightOffset / 2), {
      maxWidth,
    });
    if (client.tel) {
      doc.text(`Tél : ${client.tel}`, 10, (height += heightOffset));
    }

    height = 40 - heightOffset;
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('Adresse de livraison :', 75, (height += heightOffset));
    doc.setFontSize(7);
    doc.setFont('helvetica', 'normal');
    doc.text(commande.livr_raisonSociale || '', 75, (height += heightOffset), {
      maxWidth,
    });
    doc.text(commande.livr_adresse || '', 75, (height += heightOffset), {
      maxWidth,
    });
    doc.text(
      `${commande.livr_codePostal} ${commande.livr_ville}`,
      75,
      (height += heightOffset),
      {maxWidth},
    );
    doc.text(`E-mail : ${commande.email}`, 75, (height += heightOffset), {
      maxWidth,
    });

    if (transporteur) {
      doc.text(
        `Transporteur : ${transporteur?.libelle}`,
        75,
        (height += heightOffset),
      );
    }
  }

  /*doc.setFontSize(10);*/

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  doc.setLineDash();
  doc.rect(10, 72, 65, 70);
  doc.rect(75, 72, 65, 70);

  doc.setFontSize(9);
  doc.setFont('helvetica', 'normal');

  const steps = [
    'Cirage',
    'Résine',
    'Grattage',
    'Perçage',
    'Scan',
    'Modelage',
    'FAB 3D',
    'Montage',
  ];
  const width = 130 / steps.length;
  steps.map((step, index): void => {
    doc.rect(10 + index * width, 145, width, 20);
    doc.text(step, 10 + index * width + width / 2, 150, {align: 'center'});

    return undefined;
  });

  doc.setFontSize(7);
  if (commande.details.files?.length > 0) {
    doc.text(
      "Fichiers d'empreintes : " + commande.details.files.join(', '),
      10,
      170,
      {maxWidth: 130},
    );
  }

  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text('OG', 15, 77);
  doc.text('OD', 80, 77);

  doc.setFontSize(12);
  doc.setFont('helvetica', 'normal');

  const articlesIds: string[] = [];
  ['OG', 'OD'].map((earStr: string): void => {
    const ear = earStr as 'OG' | 'OD';
    if (commande.details[ear]?.article) {
      articlesIds.push(commande.details[ear]?.article || '');
    }
    if (commande.details[ear]?.bague) {
      articlesIds.push(commande.details[ear]?.bague || '');
    }

    return undefined;
  });

  const articlesCodes = {
    OG: '',
    OD: '',
  };

  const articles =
    (await session.fetch<Article[]>('Articles', 'getArticlesFromIDs', {
      ids: Array.from(new Set(articlesIds)),
    })) || [];
  ['OG', 'OD'].map((earStr: string, index: number): void => {
    const ear = earStr as 'OG' | 'OD';

    const details = commande.details[ear];
    if (!details) {
      return undefined;
    }

    const article = articles.find(({ID}) => ID === details.article);

    doc.setFontSize(10);

    if (article) {
      articlesCodes[earStr as 'OG' | 'OD'] = article.code;
      doc.text('1x ' + article.libelle, 15 + 65 * index, 85, {maxWidth: 60});
    }

    let height = 100;

    doc.setFont('helvetica', 'normal');
    if (details.matiere) {
      doc.text(details.matiere, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (details.forme) {
      const detail =
        details.forme.toLowerCase().indexOf('épaulement') === -1
          ? ''
          : ' ' + (details.formeEpaulement || 'Moyen');
      doc.text(details.forme + detail, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    } else if (details.produit) {
      const detail =
        details.produit.toLowerCase().indexOf('épaulement') === -1
          ? ''
          : ' ' + (details.formeEpaulement || 'Moyen');
      doc.text(details.produit + detail, 15 + 65 * index, height, {
        maxWidth: 90,
      });
      height += 5;
    }
    if (details.marque) {
      doc.text(details.marque, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (details.ecouteur) {
      doc.text(details.ecouteur, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }

    const bague = articles.find(({ID}) => ID === details.bague);

    if (bague) {
      doc.text(bague.code, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }

    if (details.couleur) {
      doc.text(details.couleur, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (details.finition) {
      doc.text(details.finition, 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (details.event) {
      doc.text(
        getLabelFromRule(details.event, eventRules, eventLabels),
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    }
    if (details.eventDiam || details.eventSurMesure) {
      doc.text(
        'Event ' + (details.eventDiam || details.eventSurMesure) + ' mm',
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    }
    if (details.tube) {
      doc.text(
        getLabelFromRule(details.tube, tubeRules, tubeLabels),
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    }
    const optionTubeArr: string[] = (
      details.optionEmbout || []
    ).map((opt): string =>
      getLabelFromRule(opt, optionTubeRules, optionTubeLabels),
    );
    if (optionTubeArr.length > 0) {
      doc.text(optionTubeArr.join(', '), 15 + 65 * index, height, {
        maxWidth: 90,
      });
      height += 5;
    }
    if (details.optionAquason && details.optionAquason === 'OPT_option01') {
      doc.text('Flotteur', 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (details.optionEP2) {
      doc.text(
        getLabelFromRule(details.optionEP2, optionRules, optionLabels),
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    }
    /* if (details.optionCordonEP2) {
      doc.text(
        'Cordon ' +
          getLabelFromRule(
            details.optionCordonEP2,
            cordColorRules,
            cordColorLabels,
          ),
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    } */
    if (
      details.optionGravureEP2 &&
      details.optionGravureEP2 === 'OPT_option06'
    ) {
      doc.text('Gravure', 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    const optionObturateurPasstopTArr = (details.optionObturateur || [])
      .concat(details.optionPasstopT || [])
      .map((opt) => getLabelFromRule(opt, optionRules, optionLabels));
    if (optionObturateurPasstopTArr.length > 0) {
      doc.text(
        optionObturateurPasstopTArr.join(', '),
        15 + 65 * index,
        height,
        {maxWidth: 90},
      );
      height += 5;
    }
    if (details.optionTir && details.optionTir === 'OPT_option01') {
      doc.text('Camouflage', 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }
    if (
      details.vernisAntibacterien &&
      details.vernisAntibacterien === 'OPT_foption01'
    ) {
      doc.text('Vernis antibactérien', 15 + 65 * index, height, {maxWidth: 90});
      height += 5;
    }

    return undefined;
  });

  let qr1: HTMLImageElement | undefined;
  let qr2: HTMLImageElement | undefined;

  const OGArtID = commande.details.OG?.article;
  const ODArtID = commande.details.OD?.article;

  if (OGArtID === ODArtID && ODArtID) {
    qr2 = await loadQRCode(
      `${commande.ID}|${commande.numeroBon}|DG|${client.code}|${commande.patient}|${articlesCodes.OG}`,
    );
  } else {
    if (ODArtID) {
      qr2 = await loadQRCode(
        `${commande.details.uuidBis}|${commande.numeroBon}|D|${client.code}|${commande.patient}|${articlesCodes.OD}`,
      );
    }
    if (OGArtID) {
      qr1 = await loadQRCode(
        `${commande.ID}|${commande.numeroBon}|G|${client.code}|${commande.patient}|${articlesCodes.OG}`,
      );
    }
  }
  {
    const both = client.specificite && commande.messageInterne;
    const height = 180;
    const maxWidth = both ? 45 : 90;
    if (client.specificite) {
      const lines = `Specificite: ${client.specificite}`
        .trim()
        .replace(/\n+/g, ' / ');

      doc.text(lines, 30, height, {maxWidth});
    }

    if (commande.messageInterne) {
      const lines = `Commentaires: ${commande.messageInterne}`
        .trim()
        .replace(/\n+/g, ' / ');
      doc.text(lines, 30 + (both ? maxWidth : 0), height, {maxWidth});
    }
  }
  if (qr1) {
    doc.addImage(qr1, 'PNG', qr2 ? 10 : 120, 180, 20, 20);
  }
  if (qr2) {
    doc.addImage(qr2, 'PNG', 120, 180, 20, 20);
  }

  return doc.output('bloburi');
};

interface Props {
  large?: boolean;
  disabled?: boolean;
  order: Partial<Order>;
}

const TechnicalModal: FC<Props> = ({large, disabled, order}) => {
  const session = useSession();

  const [dataUri, setDataUri] = useState('');

  const hideModal = (): void => {
    setDataUri('');
  };

  const getPDF = async (): Promise<void> => {
    const dataUri = await generate(session, order as Order);

    setDataUri(dataUri);
  };

  return (
    <Fragment>
      <Tooltip title={!disabled ? 'Télécharger le document technique' : null}>
        <Button
          disabled={disabled}
          shape={large ? undefined : 'circle'}
          icon="file-pdf"
          style={{margin: '0 8px'}}
          onClick={getPDF}
        >
          {large ? 'Document technique' : null}
        </Button>
      </Tooltip>
      <Modal
        width="90vw"
        visible={dataUri.length > 0}
        footer={[
          <a key="download" href={dataUri} download={`${order.patient}.pdf`}>
            <Button>Télécharger</Button>
          </a>,
          <Button
            key="close"
            type="primary"
            onClick={hideModal}
            style={{marginLeft: 8}}
          >
            Fermer
          </Button>,
        ]}
      >
        <div style={{height: '80vh'}}>
          {dataUri.length > 0 ? (
            <iframe
              title="PDF"
              src={`/pdf/web/viewer.html?file=${dataUri}`}
              width="100%"
              height="100%"
              style={{border: 0}}
            />
          ) : null}
        </div>
      </Modal>
    </Fragment>
  );
};

export default memo(TechnicalModal);
