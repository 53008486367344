/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IdClient {
  __KEY: string;
  __STAMP: number;
}

export interface IdCommandeVente {
  __KEY: string;
  __STAMP: number;
}

export interface TRFARTCollection {
  __COUNT: number;
}

export interface TRSARTCollection {
  __COUNT: number;
}

export interface IdUniteVente {
  __KEY: string;
  __STAMP: number;
}

export interface CDVLignesCollection2 {
  __COUNT: number;
}

export interface FavorisCollection {
  __COUNT: number;
}

export interface ArticlesImageCollection {
  __COUNT: number;
}

export interface IdArticle {
  ID: string;
  TRF_ARTCollection: TRFARTCollection;
  TRS_ARTCollection: TRSARTCollection;
  stock: number;
  code: string;
  libelle: string | null;
  id_uniteVente: IdUniteVente;
  id_famille: string;
  id_sousFamille: string;
  web_presentation: string;
  poids: number;
  numPageCatalogue: string;
  assemble: boolean;
  miniCde: number;
  nonRemisable: boolean;
  CDV_LignesCollection: CDVLignesCollection2;
  favorisCollection: FavorisCollection;
  articlesImageCollection: ArticlesImageCollection;
  multipleVente: number;
  uniteVente?: any;
  regle: string;
  profil: number;
}

export interface CDVLignesCollection {
  id_commandeVente: IdCommandeVente;
  quantiteVente: number;
  id_article: IdArticle;
  montantHT?: number;
  ID: string;
  code: string;
  libelle: string;
}

export interface IdTransporteur {
  __KEY: string;
  __STAMP: number;
}

export interface OREILLE {
  categorie: string | null;
  produit: string | null;
  matiere: string | null;
  forme: string | null;
  article: string | null;
  bague: string | null;
  formeEpaulement: string | null;
  couleur: string | null;
  finition: string | null;
  vernisAntibacterien: string | null;
  marque: string | null;
  ecouteur: string | null;
  optionsEcouteur: string[] | null;
  tube: string | null;
  tubeOption: string | null;
  optionEmbout: string[] | null;
  optionDeporte: string[] | null;
  event: string | null;
  eventDiam: string | null;
  eventSurMesure: string | null;
  optionAquason: string | null;
  optionAquastop: string[] | null;
  optionEP2: string | null;
  optionCordonEP2: string | null;
  optionGravureEP2: string | null;
  optionPianissimo: string[] | null;
  optionPasstopT: string[] | null;
  optionTir: string | null;
  optionObturateur: string[] | null;
  optionPasstopO: string[] | null;
  otoscan: boolean;
  repereCouleur: boolean;
  sousGarantie: boolean;
  article_regle?: string;

  typeObturateur: string | null;
  formeConque: 'demi' | null;
  formeDeporte: string | null;
  tubeCouleur: string | null;
  deporteBrand: string | null;
  typeEmbout: string | null;
  customMark: string | null;
  optionIros: string | null;
}

export interface PATIENT {
  nom: string;
  prenom?: string;
  age: number;
  surdite: string;
  serial: string;
  sexe?: string;
}

export interface Details {
  OREILLE: string;
  OG: OREILLE | null;
  OD: OREILLE | null;
  OBS_EMP?: any;
  PATIENT: PATIENT;
  files: string[];
  uuidBis?: string;
}

export interface Order {
  ID: string;
  id_client: IdClient;
  etat: ORDER_STATE;
  etatNegoce: number;
  dateSaisie: number;
  departSociete: number;
  numeroBon: string;
  numeroAffaire: string;
  synchro4D?: number;
  CDV_LignesCollection: CDVLignesCollection[];
  id_transporteur: IdTransporteur;
  dateSouhaitee: any;
  messageInterne?: any;
  livrerAvecFabrication?: boolean;
  livr_id: string;
  livr_raisonSociale: string;
  livr_adresse: string;
  livr_codePostal: string;
  livr_ville: string;
  livr_id_pays: string;
  livr_iso_pays: string;
  coupon?: any;
  contact: string;
  email: string;
  profil: number;
  etatFabrication: number;
  details: Details;
  patient: string;
  oreille: string;
  noBoite: string;
  modeEmpreinte: number;
  ATT_attente: boolean;
  ATT_motif: string | null;
}

export interface BetterOrder extends Order {
  montant: number;
}

export enum ORDER_STATE {
  EN_ATTENTE = 0,
  EN_COURS = 1,
  EXPEDIEE = 3,
  ANNULEE = 4,
  LIVRAISON_PARTIELLE = 5,
}

export const ORDER_STATE_INFO: {
  [key in ORDER_STATE]: {label: string; color: string};
} = {
  [ORDER_STATE.EN_ATTENTE]: {label: 'En attente', color: 'purple'},
  [ORDER_STATE.EN_COURS]: {label: 'En cours', color: 'royalblue'},
  [ORDER_STATE.EXPEDIEE]: {label: 'Expédiée', color: 'green'},
  [ORDER_STATE.ANNULEE]: {label: 'Annulée', color: 'crimson'},
  [ORDER_STATE.LIVRAISON_PARTIELLE]: {
    label: 'Livraison partielle',
    color: '#ff9648',
  },
};

export enum PRODUCTION_STATE {
  EN_ATTENTE = 0,
  EN_COURS = 1,
  TERMINEE = 2,
  REFUSEE = 3,
  ANNULEE = 4,
}

export const PRODUCTION_STATE_INFO: {
  [key in PRODUCTION_STATE]: {label: string; color: string};
} = {
  [PRODUCTION_STATE.EN_ATTENTE]: {label: 'En attente', color: 'black'},
  [PRODUCTION_STATE.EN_COURS]: {label: 'En cours', color: 'pink'},
  [PRODUCTION_STATE.TERMINEE]: {label: 'Terminée', color: 'forestgreen'},
  [PRODUCTION_STATE.REFUSEE]: {label: 'Refusée', color: 'crimson'},
  [PRODUCTION_STATE.ANNULEE]: {label: 'Annulée', color: 'crimson'},
};

export interface TRSARTCollection {
  __COUNT: number;
}

export interface IdModeExpedition {
  __KEY: string;
  __STAMP: number;
}

export interface ClientsCollection {
  __COUNT: number;
}

export interface ClientsCollection1 {
  __COUNT: number;
}

export interface CLTAdresseCollection {
  __COUNT: number;
}

export interface CommandesCollection {
  __COUNT: number;
}

export interface CltExpeditionCollection {
  __COUNT: number;
}

export interface IdentifiantsWebCollection {
  __COUNT: number;
}

export interface Client {
  __KEY: string;
  __STAMP: number;
  ID: string;
  TRS_ARTCollection: TRSARTCollection;
  code: string;
  codeGroupe: string;
  id_groupeClient: string;
  id_sousGroupeClient: string;
  id_modeExpedition: IdModeExpedition;
  raisonSociale: string;
  adresseGenerale: string;
  codePostal: string;
  ville: string;
  facturationPayeur: boolean;
  clientsCollection: ClientsCollection;
  clientsCollection_1: ClientsCollection1;
  email: string;
  CLT_adresseCollection: CLTAdresseCollection;
  commandesCollection: CommandesCollection;
  hash: string;
  cltExpeditionCollection: CltExpeditionCollection;
  contact: string;
  blChiffre: boolean;
  identifiantsWebCollection: IdentifiantsWebCollection;
  tel: string;
  specificite: string;
}

export type TypeaheadClient = Pick<Client, 'raisonSociale' | 'ID' | 'specificite'>;

export interface Transporteur {
  ID: string;
  libelle: string;
  delai?: number;
  prix?: number;
  clientsCollection: {__COUNT: number};
  commandesCollection: {__COUNT: number};
  cltExpeditionCollection: {__COUNT: number};
  defaut?: boolean;
}

export interface Adresse {
  ID: string;
  type: 'L' | 'LP' | 'G';
  raisonSociale: string;
  adresse: string;
  codePostal: string;
  ville: string;
  isoCountry?: string;
}

