import {useEffect, useState} from 'react';
import {Catalog, WakandaClient} from 'wakanda-client';
import {IDirectory} from 'wakanda-client/dist/wakanda-client';

export interface Adr {
  rs: string;
  adr: string;
  cp: string;
  ville: string;
  pays: string;
}

export enum UserType {
  CLIENT = 0,
  TECH = 1,
  ADMIN = 2,
}

export interface User {
  ID: string;
  groupID: string;
  name: string;
  fullName: string;
  userName: string;
  TRP: string;
  adr_livr: Adr;
  adr_fact: Adr;
  favoris: string[];
  contact: string;
  email: string;
  blChiffre: boolean;
  type: UserType;
  isGuest: boolean;
  hash: string;
}

export class WakandaService {
  public resetService?: () => void;

  private readonly wakanda = new WakandaClient({
    host: process.env.REACT_APP_WAKANDA_HOST,
    catalog: 'Utils',
  });
  private readonly directory: IDirectory = this.wakanda.directory;
  private ds: Catalog | null = null;

  private _user: User | null = null;
  public get user(): User | null {
    return this._user;
  }

  public set user(user) {
    this._user = user
      ? {...user, isGuest: user.ID === '00000000000000000000000000000000'}
      : null;
  }

  public async getCatalog(): Promise<Catalog> {
    return this.ds ? this.ds : (this.ds = await this.wakanda.getCatalog());
  }

  private async getUser(): Promise<User | null> {
    if (this.user) {
      return this.user;
    }

    this.user = await this._fetch<User>('Utils', 'getUser');
    return this.user;
  }

  public hasAlreadyAttemptedRestore = false;
  public restorePromise: Promise<User | null> | undefined;
  public useRestore(): [boolean, boolean] {
    const [restore, setRestore] = useState<[boolean, boolean]>([
      this.hasAlreadyAttemptedRestore,
      !!this.user && !this.user.isGuest,
    ]);

    useEffect(() => {
      let abort = false;
      (async () => {
        this.restorePromise = this.restorePromise || this.getUser();
        await this.restorePromise;

        if (abort) {
          return;
        }

        setRestore([
          (this.hasAlreadyAttemptedRestore = true),
          !!this.user && !this.user.isGuest,
        ]);
      })();

      return () => {
        abort = true;
      };
    }, []);

    return restore;
  }

  public async login(
    username: string,
    password: string,
    remember = true,
  ): Promise<boolean> {
    const success = await this.directory.login(
      username,
      password,
      !remember ? 0 : undefined,
    );
    if (success) {
      this.user = null;
      try {
        await this.getUser();
      } catch {
        console.log('getUser failed, trying again');
        await new Promise((success) => setTimeout(success, 2500));
        await this.getUser();
      }
    }

    return success;
  }

  public async fetch<T = {}>(
    target: string,
    func: string,
    params = {},
  ): Promise<T | void> {
    if (!(await this.getUser())) {
      // throw new Error('Must be logged in');
      return;
    }

    return this._fetch(target, func, params);
  }

  private async _fetch<T = {}>(
    target: string,
    func: string,
    params = {},
  ): Promise<T> {
    try {
      const catalog = await this.getCatalog();

      return await catalog.Utils.handleRequest(target, func, params);
    } catch (err) {
      this.user = null;
      this.resetService?.();
      throw err;
    }
  }

  public async logout(): Promise<boolean> {
    this.user = null;
    return this.directory.logout();
  }
}
