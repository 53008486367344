import React, {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Product,
  useCurrentCategory,
  useCurrentProduct,
  useCurrentSelectedOptions,
  DeporteBrand,
  useCurrentBrand,
} from '../../contexts/Quote';
import {DEPORTE_SHAPE} from '../Options/data';
import ProductSelection from './ProductSelection';

const Products: FC<{parentPath: string}> = ({parentPath}) => {
  const [category] = useCurrentCategory();
  const [product, setProduct] = useCurrentProduct();
  const [brand, setBrand] = useCurrentBrand();
  const [_, setSelectedOptions] = useCurrentSelectedOptions();
  const history = useHistory();

  if (!category) {
    return null;
  }

  const onSelect = (_product: Product): void => {
    const hasBrands = _product?.name === DEPORTE_SHAPE.DEPORTE;

    if (_product !== product) {
      setProduct(_product);
      setSelectedOptions([]);
      if (brand && !hasBrands) {
        setBrand((undefined as unknown) as DeporteBrand);
      }
    }

    history.push([parentPath, hasBrands ? 'brands' : 'options'].join('/'));
  };

  return (
    <ProductSelection
      products={category.products}
      onSelect={onSelect}
      value={product || undefined}
    />
  );
};

export default memo(Products);
