import {ProductCategory} from '../../contexts/Quote';
import {
  CLASSIQUE_SHAPE,
  DEPORTE_BRAND,
  DEPORTE_SHAPE,
  PRODUCT_CATEGORY,
  PROTECTION_SHAPE,
} from '../Options/data';

export const productCategories: ProductCategory[] = [
  {
    name: PRODUCT_CATEGORY.CLASSIQUE,
    label: 'Embouts classiques',
    img: '/categories/classique.jpg',
    products: [
      {
        name: CLASSIQUE_SHAPE.CONDUIT,
        label: 'Conduit',
        img: '/classique/shape/canule.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_EPAULEMENT,
        label: 'Conduit épaulement',
        img: '/classique/shape/canule-epaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.CANULE_DEMI_EPAULEMENT,
        label: 'Conduit 1/2 épaulement',
        img: '/classique/shape/canuledemiepaulement.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.SQUELETTE,
        label: 'Phantomold',
        img: '/classique/shape/squelette.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.FOND_CONQUE,
        label: 'Coquille',
        img: '/classique/shape/fondconque.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.DEMI_CONQUE,
        label: 'Demi-conque',
        img: '/classique/shape/demiconque.jpg',
      },
      {
        name: CLASSIQUE_SHAPE.PINCE_CRABE,
        label: 'Silouhette',
        img: '/classique/shape/pincecrabe.jpg',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.DEPORTE,
    label: 'Ecouteurs déportés, Micro-tubes',
    img: '/categories/deporte.jpg',
    products: [
      {
        name: DEPORTE_SHAPE.DEPORTE,
        label: 'Embouts pour écouteurs déportés',
        img: '/deporte/shape/embouts.jpg',
      },
    ],
    brands: [
      {
        name: DEPORTE_BRAND.SIEMENS,
        label: 'Siemens',
        img: '/deporte/brand/siemens.jpg',
      },
      {
        name: DEPORTE_BRAND.OTICON,
        label: 'Oticon',
        img: '/deporte/brand/oticon.jpg',
      },
      {
        name: DEPORTE_BRAND.BELTONE,
        label: 'Beltone',
        img: '/deporte/brand/beltone.jpg',
      },
      {
        name: DEPORTE_BRAND.WIDEX,
        label: 'Widex',
        img: '/deporte/brand/widex.jpg',
      },
      {
        name: DEPORTE_BRAND.PHONAK,
        label: 'Phonak',
        img: '/deporte/brand/phonak.jpg',
      },
      {
        name: DEPORTE_BRAND.STARKEY,
        label: 'Starkey',
        img: '/deporte/brand/starkey.png',
      },
    ],
  },
  {
    name: PRODUCT_CATEGORY.PROTECTION,
    label: 'Embouts protecteurs',
    img: '/categories/protection.jpg',
    products: [
      {
        name: PROTECTION_SHAPE.SOMMEIL,
        label: 'Embout sommeil',
        img: '/protection/shape/sommeil.jpg',
      },
      {
        name: PROTECTION_SHAPE.SWEETNIGHT,
        label: 'Sweetnight',
        img: '/protection/shape/sweetnight.jpg',
      },
      {
        name: PROTECTION_SHAPE.AQUASTOP,
        label: 'Aquastop',
        img: '/protection/shape/aquastop.jpg',
      },
      {
        name: PROTECTION_SHAPE.OBTURATEUR,
        label: 'Obturateur',
        img: '/protection/shape/obturateur.jpg',
      },
      {
        name: PROTECTION_SHAPE.GAME_EAR,
        label: 'Game Ear',
        img: '/protection/shape/gameear.jpg',
      },
      {
        name: PROTECTION_SHAPE.PASSTOP_OR,
        label: 'Passtop OR (résine)',
        img: '/protection/shape/passtopor.jpg',
      },
      {
        name: PROTECTION_SHAPE.AQUASON,
        label: 'Aquason',
        img: '/protection/shape/aquason.jpg',
      },
      {
        name: PROTECTION_SHAPE.PASSTOP_EP2,
        label: 'Passtop EP2',
        img: '/protection/shape/passtopEP2.jpg',
      },
      {
        name: PROTECTION_SHAPE.PASSTOP_A,
        label: 'Passtop A',
        img: '/protection/shape/passtop_a.jpg',
      },
    ],
  },
];
