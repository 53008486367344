import {css} from '@emotion/core';
import {Col, Row} from 'antd';
import React, {FC, Fragment} from 'react';
import {Option, TBoolean, useCurrentLockedEars} from '../../contexts/Quote';
import {colors} from '../../util/render';
import {OptionsGroup} from './OptionsGroup';
import {OptionsGroupPicker} from './OptionsGroupPicker';
import {
  getSelectedEars,
  Group,
  OPTION_GROUP_CHECK_TYPE,
  SelectableOption,
  SELECTED_EARS,
} from './static';
import {darken} from 'polished';

interface Props {
  ears: TBoolean;
  groups: Group[];
  options: SelectableOption[];
  selectedGroup: Group | undefined;
  setSelectedOption: (
    option: Option,
    checkType: OPTION_GROUP_CHECK_TYPE,
  ) => void;
}

export const OptionsGroupsRows: FC<Props> = ({
  ears,
  groups,
  options,
  selectedGroup,
  setSelectedOption,
}) => {
  const [lockedEars] = useCurrentLockedEars();
  if (!groups.length) {
    return null;
  }

  return (
    <Fragment>
      <div>
        <div
          css={[
            {
              display: 'flex',
              textAlign: 'center',
              color: 'white',
              backgroundColor: darken(0.25, colors.secondary),
            },
            css`
              padding: 5px 0;
              @media (min-width: 992px) {
                padding: 10px 0;
                font-size: 20px;
              }
            `,
          ]}
        >
          <Col css={{flex: 2}} />
          <Col css={{flex: 12}}>Options d&apos;oreilles</Col>
          {getSelectedEars(ears) !== SELECTED_EARS.BOTH || !lockedEars ? (
            ears.map((enabled, earIndex) =>
              [
                {xs: 0, lg: 1},
                {xs: 1, lg: 0},
              ].map((props, responsiveIndex) => (
                <Col
                  key={`${earIndex}_${responsiveIndex}`}
                  css={{flex: 1}}
                  {...props}
                >
                  {enabled
                    ? !props.xs
                      ? earIndex
                        ? 'Droite'
                        : 'Gauche'
                      : earIndex
                      ? 'D'
                      : 'G'
                    : ''}
                </Col>
              )),
            )
          ) : (
            <Col css={{flex: 2}}>Les deux</Col>
          )}
        </div>
      </div>
      <Row>
        <Col span={12}>
          <OptionsGroupPicker groups={groups} ears={ears} />
        </Col>
        <Col span={12}>
          <OptionsGroup
            options={options}
            checkType={
              selectedGroup?.checkType ||
              (selectedGroup?.unique
                ? OPTION_GROUP_CHECK_TYPE.RADIO
                : OPTION_GROUP_CHECK_TYPE.CHECKBOX)
            }
            setSelectedOption={setSelectedOption}
          />
        </Col>
      </Row>
    </Fragment>
  );
};
