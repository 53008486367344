import {css} from '@emotion/core';
import {Steps} from 'antd';
import {StepProps} from 'antd/lib/steps';
import React, {FC, memo} from 'react';
import {Redirect, useHistory, useLocation} from 'react-router-dom';
import {useQuote} from '../../contexts/Quote';
import {matchRoutePath} from '../../util/path';
import {DEPORTE_SHAPE} from '../Options/data';

const cssSteps = css`
  margin: 10px 0;
  .ant-steps-item:not(.ant-steps-item-disabled) {
    cursor: pointer;
  }
`;

const {Step} = Steps;

interface QuoteSteps extends StepProps {
  path: string;
}

const QuoteSteps: FC<{parentPath: string}> = ({parentPath}) => {
  const history = useHistory();
  const location = useLocation();
  const [
    {
      patient,
      currentLine: {category, product, brand},
    },
  ] = useQuote();

  const hasBrands = product?.name === DEPORTE_SHAPE.DEPORTE;

  const steps: QuoteSteps[] = [
    {path: 'patient', title: 'Références'},
    {path: 'categories', title: 'Catégories', disabled: !patient},
    {path: 'products', title: 'Produits', disabled: !category},
    ...(hasBrands
      ? [{path: 'brands', title: 'Marques', disabled: !product}]
      : []),
    {
      path: 'options',
      title: 'Options',
      disabled: hasBrands ? !brand || !product : !product,
    },
  ];

  const currentStep = matchRoutePath(
    location.pathname,
    steps,
    `${parentPath}/`,
  );
  const currentStepIndex = currentStep ? steps.indexOf(currentStep) : -1;

  if (currentStep && currentStep.disabled) {
    return <Redirect to={[parentPath, steps[0].path].join('/')} />;
  }

  return (
    <Steps
      size="small"
      current={currentStepIndex >= 0 ? currentStepIndex : undefined}
      css={cssSteps}
    >
      {steps.map(({path, ...props}) => (
        <Step
          key={path}
          {...props}
          onClick={
            props.disabled
              ? undefined
              : () => history.push([parentPath, path].join('/'))
          }
        />
      ))}
    </Steps>
  );
};

export default memo(QuoteSteps);
