import {Col, Row} from 'antd';
import {readableColor} from 'polished';
import React, {CSSProperties, FC} from 'react';
import {Redirect, useLocation} from 'react-router-dom';
import {
  Option,
  TBoolean,
  UndefinedTuple,
  useCurrentCategory,
  useCurrentEars,
  useCurrentProduct,
  useCurrentSelectedOptions,
  useCurrentBrand,
  DeporteBrand,
} from '../../contexts/Quote';
import {
  BOOLEAN_VALUE,
  brandLabels,
  COLOR,
  colorHex,
  colorLabels,
  CommonOptionDataKeys,
  DEPORTE_BRAND,
  earBudLabels,
  EAR_BUD,
  EVENT,
  eventLabels,
  FILTER,
  filterLabels,
  getTypeLabel,
  isOptionData,
  Material,
  materialLabels,
  MISCELLANEOUS,
  miscellaneousLabels,
  OPTION,
  OptionDataContainer,
  optionLabels,
  optionsTree,
  optionTubeLabels,
  OPTION_TUBE,
  ProductCategoryType,
  Shape,
  shapeLabels,
  SHOULDER,
  shoulderLabels,
  SURFACE,
  surfaceLabels,
  TUBE,
  tubeLabels,
  TYPE,
  OBTURATEUR_PRODUCT_TYPE,
  obturateurProductTypeLabels,
  DEPORTE_OPTION,
  deporteOptionLabels,
  finishLabels,
  FINISH,
  OptionValueMap,
  earWaxShieldLabels,
  EARWAX_SHIELD,
  cordColorHex,
  CORD_COLOR,
  cordColorLabels,
} from './data';
import EarsInput from './EarsInput';
import FormButtons from './FormButtons';
import {OptionsGroupsRows} from './OptionsGroupsRows';
import RulesDebug from './RulesDebug';
import {
  Group,
  OPTION_GROUP_CHECK_TYPE,
  SelectableOption,
  getSelectedEars,
  SELECTED_EARS,
} from './static';

const deepTypes = (
  types: {name: TYPE; required: boolean; unique: boolean}[],
  data: ProductCategoryType,
): void => {
  if (!data.type) {
    const opt = data as OptionDataContainer;
    if (opt.options) {
      opt.options.map((option): void => {
        const optionType = types.find((type) => type.name === option.type);
        if (!optionType) {
          types.push({
            name: option.type,
            required: option.required || false,
            unique: option.unique || false,
          });
        }

        return undefined;
      });
    }
    return;
  }

  const keys = Object.keys(data).filter(
    (key) => !CommonOptionDataKeys.includes(key),
  );

  const optionType = types.find((type) => type.name === data.type);
  if (!optionType) {
    const {required = false, unique = false} = isOptionData(data)
      ? data
      : {required: true, unique: true};

    types.push({
      name: data.type as TYPE,
      required,
      unique,
    });
  }

  keys.map((key): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    deepTypes(types, data[key]);

    return undefined;
  });
};

export const getOptionLabel = (type: TYPE, name: string): string => {
  switch (type) {
    case TYPE.BRAND:
      return brandLabels[name as DEPORTE_BRAND];
    case TYPE.DEPORTE_BRAND:
    case TYPE.SHAPE:
      return shapeLabels[name as Shape];
    case TYPE.MATERIAL:
      return materialLabels[name as Material];
    case TYPE.COLOR:
      return colorLabels[name as COLOR];
    case TYPE.FILTER:
      return filterLabels[name as FILTER];
    case TYPE.SURFACE:
      return surfaceLabels[name as SURFACE];
    case TYPE.EVENT:
    case TYPE.EVENT_TYPE_AND_SIZE:
      return eventLabels[name as EVENT];
    case TYPE.TUBE:
      return tubeLabels[name as TUBE];
    case TYPE.OPTION_TUBE:
      return optionTubeLabels[name as OPTION_TUBE];
    case TYPE.SHOULDER:
      return shoulderLabels[name as SHOULDER];
    case TYPE.EAR_BUD:
      return earBudLabels[name as EAR_BUD];
    case TYPE.MISCELLANEOUS:
      return miscellaneousLabels[name as MISCELLANEOUS];
    case TYPE.EARWAX_SHIELDING:
      return earWaxShieldLabels[name as EARWAX_SHIELD];
    case TYPE.OPTION:
      return optionLabels[name as OPTION];
    case TYPE.PASSTOP_OR_PRODUCT_TYPE:
      return ['Passtop O.', name].join('');
    case TYPE.OBTURATEUR_PRODUCT_TYPE:
      return obturateurProductTypeLabels[name as OBTURATEUR_PRODUCT_TYPE];
    case TYPE.DEPORTE_OPTION:
      return deporteOptionLabels[name as DEPORTE_OPTION];
    case TYPE.FINISH:
      return finishLabels[name as FINISH];
    case TYPE.COLOR_MARKER:
    case TYPE.OTOSCAN:
    case TYPE.EAR_BUD_ASSEMBLY:
      return getTypeLabel(type);
    case TYPE.FLOAT:
      return 'Flotteur';
    case TYPE.ENGRAVING:
      return 'Gravure Prénom + Nom';
    case TYPE.CAMO:
      return 'Camouflage';
    case TYPE.CORD_COLOR:
      return cordColorLabels[name as CORD_COLOR];
    default:
      return '%MISSING_OPTION_LABEL%';
  }
};

const getOptionStyle = (
  type: TYPE,
  name: string,
): CSSProperties | undefined => {
  const color = ((): string => {
    switch (type) {
      case TYPE.COLOR:
        return colorHex[name as COLOR];
      case TYPE.CORD_COLOR:
        return cordColorHex[name as CORD_COLOR];
      default:
        return '';
    }
  })();

  return color
    ? {backgroundColor: color, color: readableColor(color)}
    : undefined;
};

const deepOptions = (
  options: SelectableOption[],
  data: ProductCategoryType,
  type: TYPE,
  ears: TBoolean,
  selectedOptions: Option[],
  parentSelected: TBoolean,
  brand: DeporteBrand | null,
): boolean => {
  let forceChange = false;

  if (!data.type) {
    const opt = data as OptionDataContainer;
    if (opt.options) {
      opt.options.map((option): void => {
        if (option.type !== type) {
          return undefined;
        }

        const values =
          (brand && option.valuesBrandCallback?.(brand.name)) || option.values;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        values.map((_option: string | OptionValueMap): void => {
          const optionsValueMap =
            typeof _option === 'string' ? null : (_option as OptionValueMap);
          const optionName = optionsValueMap
            ? optionsValueMap.value
            : (_option as string);

          let existingOption = options.find(
            ({type: optionType, name}) =>
              type === optionType && name === optionName,
          );
          if (!existingOption) {
            existingOption = {
              type: option.type,
              label: getOptionLabel(option.type, optionName),
              style: getOptionStyle(option.type, optionName),
              name: optionName,
              disabled: [0, 1].map((index) =>
                optionsValueMap?.disabledCallback
                  ? optionsValueMap.disabledCallback(selectedOptions, index)
                  : !ears[index] || !parentSelected[index],
              ) as TBoolean,
              checked: [false, false],
            };

            options.push(
              Object.assign(
                existingOption,
                optionsValueMap ? {optionsValueMap} : {},
              ),
            );
          } else {
            if (existingOption.disabled[0]) {
              existingOption.disabled[0] = !ears[0] || !parentSelected[0];
            }
            if (existingOption.disabled[1]) {
              existingOption.disabled[1] = !ears[1] || !parentSelected[1];
            }
          }

          let selected: TBoolean = [false, false];
          let value: UndefinedTuple<number | string> = [undefined, undefined];
          const selectedOption = selectedOptions.find(
            ({type: optionType, name}) =>
              type === optionType && name === optionName,
          );
          if (selectedOption) {
            [selected, value] = [
              selectedOption.selected,
              selectedOption.value || value,
            ];
          }

          existingOption.checked[0] =
            !existingOption.disabled[0] && selected[0];
          existingOption.checked[1] =
            !existingOption.disabled[1] && selected[1];

          if (optionsValueMap?.alwaysSelected) {
            const checked: TBoolean = [
              ears[0] && !existingOption.disabled[0],
              ears[1] && !existingOption.disabled[1],
            ];

            if (existingOption.checked.toString() !== checked.toString()) {
              existingOption.checked = checked;
              forceChange = true;
            }
          } else if (optionsValueMap?.defaultSelected) {
            const otherSelected = [0, 1].map((earIndex) =>
              selectedOptions.find(
                ({selected, name: otherOptionName, type: otherOptionType}) => {
                  const otherOptionData = option.values.find(
                    (optionData) =>
                      typeof optionData === 'object' &&
                      otherOptionName === optionData.value,
                  );

                  const otherOptionRadioGroup =
                    otherOptionData &&
                    typeof otherOptionData === 'object' &&
                    otherOptionData.radioGroup;

                  return (
                    type === otherOptionType &&
                    optionsValueMap?.radioGroup === otherOptionRadioGroup &&
                    selected[earIndex] &&
                    otherOptionName !== optionName
                  );
                },
              ),
            );

            const checked: TBoolean = [
              ears[0] && !otherSelected[0],
              ears[1] && !otherSelected[1],
            ];

            if (
              (ears[0] || ears[1]) &&
              existingOption.checked.toString() !== checked.toString()
            ) {
              existingOption.checked = checked;
              forceChange = true;
            }
          }

          existingOption.value = existingOption.value || [undefined, undefined];
          existingOption.value[0] = !existingOption.disabled[0]
            ? value[0]
            : undefined;
          existingOption.value[1] = !existingOption.disabled[1]
            ? value[1]
            : undefined;

          return undefined;
        });
        return undefined;
      });
    }

    return forceChange;
  }

  const keys = Object.keys(data).filter(
    (key) => !CommonOptionDataKeys.includes(key),
  );

  if (data.type === type) {
    keys.map((optionName: string): void => {
      const optName = optionName === 'values' ? BOOLEAN_VALUE : optionName;
      let existingOption = options.find(
        ({type: optionType, name}) => type === optionType && name === optName,
      );
      if (!existingOption) {
        existingOption = {
          type: data.type as TYPE,
          label: getOptionLabel(data.type as TYPE, optName),
          style: getOptionStyle(data.type as TYPE, optName),
          name: optName,
          disabled: [
            !ears[0] || !parentSelected[0],
            !ears[1] || !parentSelected[1],
          ],
          checked: [false, false],
        };

        options.push(existingOption);
      } else {
        if (existingOption.disabled[0]) {
          existingOption.disabled[0] = !ears[0] || !parentSelected[0];
        }
        if (existingOption.disabled[1]) {
          existingOption.disabled[1] = !ears[1] || !parentSelected[1];
        }
      }

      let selected: TBoolean = [false, false];
      let value: UndefinedTuple<number | string> = [undefined, undefined];
      const selectedOption = selectedOptions.find(
        ({type: optionType, name}) => type === optionType && name === optName,
      );
      if (selectedOption) {
        [selected, value] = [
          selectedOption.selected,
          selectedOption.value || value,
        ];
      }

      existingOption.checked[0] = !existingOption.disabled[0] && selected[0];
      existingOption.checked[1] = !existingOption.disabled[1] && selected[1];

      existingOption.value = existingOption.value || [undefined, undefined];
      existingOption.value[0] = !existingOption.disabled[0]
        ? value[0]
        : undefined;
      existingOption.value[1] = !existingOption.disabled[1]
        ? value[1]
        : undefined;

      return undefined;
    });
  }

  keys.map((key): void => {
    const parentSelected = selectedOptions.find(({name}) => name === key); // TODO store parent type in children?
    const selected: TBoolean = (parentSelected && parentSelected.selected) || [
      false,
      false,
    ];

    forceChange =
      forceChange ||
      deepOptions(
        options,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        data[key],
        type,
        ears,
        selectedOptions,
        selected,
        brand,
      );

    return undefined;
  });

  return forceChange;
};

const findOptionFromSelectableOptions = (
  option: Option,
  options: SelectableOption[],
): SelectableOption | undefined =>
  options.find(({name}) => name === option.name);

const setSelectedOption = (
  option: Option,
  _checkType: OPTION_GROUP_CHECK_TYPE,
  selectedOptions: Option[],
  setSelectedOptions: (options: Option[]) => void,
  options: SelectableOption[],
): void => {
  /* console.trace(
    selectedOptions.map(({name, selected, }) => ({
      name,
      selected: selected.toString(),
    })),
  ); */
  const removedIndexes: number[] = [];
  const remove =
    option.selected.filter((selected) => selected === true).length === 0;

  const valueMapData = findOptionFromSelectableOptions(option, options)
    ?.optionsValueMap;
  const checkType = valueMapData?.type || _checkType;

  const index = selectedOptions.findIndex(
    ({type, name}) => type === option.type && name === option.name,
  );
  if (index !== -1) {
    if (remove) {
      removedIndexes.push(index);
    } else {
      selectedOptions[index].selected = option.selected;
      selectedOptions[index].value = option.value;
    }
  } else if (!remove) {
    selectedOptions.push(option);
  }

  if (
    [
      OPTION_GROUP_CHECK_TYPE.RADIO,
      OPTION_GROUP_CHECK_TYPE.EVENT_INPUT,
      OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
      OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT,
    ].includes(checkType) &&
    option.name !== TUBE.COLORED
  ) {
    const otherOptions = selectedOptions.filter((_option) => {
      const {name, type} = _option;
      const _valueMapData = findOptionFromSelectableOptions(_option, options)
        ?.optionsValueMap;

      if (_valueMapData) {
        if (
          ![
            OPTION_GROUP_CHECK_TYPE.RADIO,
            OPTION_GROUP_CHECK_TYPE.EVENT_INPUT,
            OPTION_GROUP_CHECK_TYPE.MARK_INPUT,
            OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT,
          ].includes(
            (_valueMapData.type as OPTION_GROUP_CHECK_TYPE) || checkType,
          ) ||
          _valueMapData.radioGroup !== valueMapData?.radioGroup
        ) {
          return false;
        }
      }

      return type === option.type && name !== option.name;
    });

    otherOptions.map((otherOption): void => {
      if (otherOption.selected[0] && option.selected[0]) {
        otherOption.selected[0] = false;
        otherOption.value && (otherOption.value[0] = undefined);
      }
      if (otherOption.selected[1] && option.selected[1]) {
        otherOption.selected[1] = false;
        otherOption.value && (otherOption.value[1] = undefined);
      }

      if (
        otherOption.selected.filter((selected) => selected === true).length ===
        0
      ) {
        removedIndexes.push(selectedOptions.indexOf(otherOption));
      }

      return undefined;
    });
  }

  setSelectedOptions(
    selectedOptions.filter(
      ({selected}, index) =>
        selected.find((_selected) => _selected) &&
        !removedIndexes.includes(index),
    ),
  );

  return undefined;
};

export const OptionsGroups: FC<{parentPath: string}> = ({parentPath}) => {
  const [category] = useCurrentCategory();
  const [product] = useCurrentProduct();
  const [brand] = useCurrentBrand();
  const [ears] = useCurrentEars();
  const [selectedOptions, setSelectedOptions] = useCurrentSelectedOptions();

  const {pathname} = useLocation();
  const pathTokens = pathname.split('/');

  const groups: Group[] = [];
  const options: SelectableOption[] = [];

  let selectedGroup: Group | undefined;
  if (category && product) {
    const selectedProduct = optionsTree[category.name][product.name];
    if (!selectedProduct) {
      return null;
    }

    const types: {name: TYPE; required: boolean; unique: boolean}[] = [];
    deepTypes(types, selectedProduct);
    groups.push(
      ...types.map((type) => ({
        ...type,
        label: getTypeLabel(type.name),
        disabled: true,
        checkType: [TYPE.EVENT, TYPE.EVENT_TYPE_AND_SIZE].includes(type.name)
          ? OPTION_GROUP_CHECK_TYPE.EVENT_INPUT
          : type.name === TYPE.DEPORTE_OPTION
          ? OPTION_GROUP_CHECK_TYPE.MARK_INPUT
          : type.name === TYPE.TUBE
          ? OPTION_GROUP_CHECK_TYPE.TUBE_COLOR_INPUT
          : undefined,
      })),
    );

    selectedGroup = groups.find(
      ({name}) => name === pathTokens[pathTokens.length - 1],
    );

    if (!selectedGroup && groups.length > 0) {
      return <Redirect to={['options', groups[0].name].join('/')} />;
    }

    if (selectedGroup) {
      deepOptions(
        options,
        selectedProduct,
        selectedGroup.name as TYPE,
        ears,
        selectedOptions,
        ears,
        brand,
      );
      let change = false;
      const removedIndexes: number[] = [];
      selectedOptions.map((option, index): void => {
        const typeOptions: SelectableOption[] = [];
        deepOptions(
          typeOptions,
          selectedProduct,
          option.type,
          ears,
          selectedOptions,
          [true, true],
          brand,
        );

        const opt = typeOptions.find(({name}) => name === option.name);
        if (!opt) {
          return undefined;
        }

        if (option.selected.toString() !== opt.checked.toString()) {
          change = true;
          option.selected = [...opt.checked] as TBoolean;

          if (
            option.selected.filter((selected) => selected === true).length === 0
          ) {
            removedIndexes.push(index);
          }
        }

        return undefined;
      });
      if (change) {
        setSelectedOptions(
          selectedOptions.filter(
            ({selected}, index) =>
              selected.find((_selected) => _selected) &&
              !removedIndexes.includes(index),
          ),
        );
      }

      groups.map((group): void => {
        const groupOptions: SelectableOption[] = [];

        const forceChange = deepOptions(
          groupOptions,
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          selectedProduct,
          group.name as TYPE,
          ears,
          selectedOptions,
          ears,
          brand,
        );

        const enabledOptions = groupOptions.filter(
          (option) =>
            option.disabled.filter(
              (disabled, index) => ears[index] && !disabled,
            ).length > 0,
        );
        group.disabled = enabledOptions.length === 0;

        if (forceChange) {
          setSelectedOptions([
            ...enabledOptions
              .filter(({checked}) => checked.find((selected) => selected))
              .map<Option>(({checked, label, name, type}) => ({
                type,
                label,
                name,
                selected: checked,
              })),
            ...selectedOptions,
          ]);
        }

        return undefined;
      });
    }
  }

  const onOptionSelect = (
    option: Option,
    checkType: OPTION_GROUP_CHECK_TYPE,
  ): void => {
    setSelectedOption(
      option,
      checkType,
      selectedOptions,
      setSelectedOptions,
      options,
    );
  };

  return (
    <Row>
      <Col span={24}>
        <FormButtons groups={groups} parentPath={parentPath}>
          <EarsInput />
        </FormButtons>
        <p
          css={{
            textAlign: 'center',
            position: 'relative',
            top: 8,
            fontStyle: 'italic',
          }}
        >
          {getSelectedEars(ears) === SELECTED_EARS.BOTH
            ? 'Pour sélectionner un choix sur les deux oreilles, cliquez sur le libellé du choix'
            : '\u00A0'}
        </p>
        <OptionsGroupsRows
          ears={ears}
          groups={groups}
          options={options}
          selectedGroup={selectedGroup}
          setSelectedOption={onOptionSelect}
        />
      </Col>
      <Col span={24}>
        {groups.length ? (
          <FormButtons groups={groups} parentPath={parentPath} />
        ) : null}
      </Col>
      <RulesDebug />
    </Row>
  );
};
