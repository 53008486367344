import React, {FC, memo} from 'react';
import {Adresse} from '../Orders/data';
import countries from '../util/countries.json';

interface ShipmentValueProps {
  address: Adresse;
}
const ShipmentValue: FC<ShipmentValueProps> = ({address}) => {
  return (
    <div>
      <p>{address?.raisonSociale}</p>
      <p>{address?.adresse}</p>
      <p>
        {address?.codePostal} {address?.ville}
      </p>
      <p>
        <span
          className={`flag-icon flag-icon-${(
            address?.isoCountry ?? 'FR'
          ).toLowerCase()}`}
          css={{marginRight: 5}}
        />
        {countries[(address?.isoCountry as keyof typeof countries) ?? 'FR']}
      </p>
    </div>
  );
};

export default memo(ShipmentValue);
