import {Button, Col, Form, Row} from 'antd';
import React, {FC, memo} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {getBaseQuoteOptions, useQuote} from '../../contexts/Quote';
import {useUser} from '../../contexts/Session';
import {UserType} from '../../util/fetch-wakanda';
import {OptionsGroups} from '../Options/OptionsGroups';
import HeaderSummary from './HeaderSummary';
import PatientForm from './patient/Form';
import ProductCategories from './ProductCategories';
import Products from './Products';
import Steps from './Steps';
import Brands from './Brands';

// const parentPath = '/quote';
const Quote: FC<{parentPath: string}> = ({parentPath}) => {
  const [quote, setQuote] = useQuote();
  const user = useUser();
  const history = useHistory();

  if (!user) {
    return <Redirect to="/login" push={true} />;
  }

  const isQuoteEditMode = !!quote.lines[0]?.id;

  if (parentPath === '/quote' && isQuoteEditMode) {
    setQuote(getBaseQuoteOptions());
  }

  const WrappedPatientForm = Form.create({
    name: 'patientForm',
  })((props) => (
    <PatientForm
      {...props}
      patientOnly={true} /* user.type === UserType.CLIENT */
      submitButton={
        <Button
          type="primary"
          htmlType="submit"
          icon={!isQuoteEditMode ? 'plus' : 'edit'}
          size="large"
          css={{float: 'right'}}
        >
          {!isQuoteEditMode ? 'Créer une commande' : 'Modifier la commande'}
        </Button>
      }
      afterSubmit={() => history.push(`${parentPath}/categories`)}
    />
  ));

  return (
    <Row
      type="flex"
      justify="center"
      css={{padding: 20, margin: '0 !important'}}
      gutter={20}
    >
      <Col span={24} xxl={5} /*css={{borderRight: 'solid #ccc 1px'}}*/>
        <HeaderSummary
          parentPath={parentPath}
          patientOnly={true} /* user.type === UserType.CLIENT */
        />
      </Col>
      <Col span={24} xxl={14}>
        <Steps parentPath={parentPath} />
        <Switch>
          <Redirect
            exact
            from={parentPath}
            to={[parentPath, 'patient'].join('/')}
          />
          <Route path={[parentPath, 'patient'].join('/')}>
            <WrappedPatientForm />
          </Route>
          <Route path={[parentPath, 'categories'].join('/')}>
            <ProductCategories parentPath={parentPath} />
          </Route>
          <Route path={[parentPath, 'products'].join('/')}>
            <Products parentPath={parentPath} />
          </Route>
          <Route path={[parentPath, 'brands'].join('/')}>
            <Brands parentPath={parentPath} />
          </Route>
          <Route path={[parentPath, 'options'].join('/')}>
            <OptionsGroups parentPath={parentPath} />
          </Route>
        </Switch>
      </Col>
    </Row>
  );
};

export default memo(Quote);
