import {Col} from 'antd';
import React, {FC, memo} from 'react';
import {useCurrentLine, useQuote} from '../../contexts/Quote';
import {getRules} from '../Summary/util';

const RulesDebug: FC = () => {
  const [quote] = useQuote();
  const [currentLine] = useCurrentLine();

  const {rules} = getRules(quote, currentLine);

  return (
    <Col span={24}>
      <p>Rules:</p>
      {rules.map((rule, index) => (
        <p style={{fontFamily: 'monospace'}} key={index}>
          {rule}
        </p>
      ))}
    </Col>
  );
};

export default memo(RulesDebug);
