import {CSSPropertiesWithMultiValues} from '@emotion/serialize';
import {TBoolean, UndefinedTuple} from '../../contexts/Quote';
import {TYPE, OptionValueMap} from './data';

export enum EAR {
  LEFT,
  RIGHT,
}

export interface Ear {
  ear: EAR;
  disabledGlobal: boolean;
  disabled: boolean;
  checked: boolean;
}

export interface SelectableOption {
  type: TYPE;
  name: string;
  label: string;
  disabled: TBoolean;
  checked: TBoolean;
  style?: CSSPropertiesWithMultiValues;
  value?: UndefinedTuple<number | string>;
  optionsValueMap?: OptionValueMap;
}

export interface OptionProps {
  label: string;
  left: Ear;
  right: Ear;
}

export enum OPTION_GROUP_TYPE {
  MATERIAL,
  COLOR,
}

export enum OPTION_GROUP_CHECK_TYPE {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  EVENT_INPUT = 'EVENT_INPUT',
  MARK_INPUT = 'MARK_INPUT',
  TUBE_COLOR_INPUT = 'TUBE_COLOR_INPUT',
}

export enum SELECTED_EARS {
  NONE = 'NONE',
  BOTH = 'BOTH',
  LEFT_ONLY = 'LEFT_ONLY',
  RIGHT_ONLY = 'RIGHT_ONLY',
}

export type CheckFunction = (
  type: TYPE,
  name: string,
  label: string,
  selected: TBoolean,
  value?: UndefinedTuple<number | string>,
) => void;

export interface Group {
  name: string;
  label: string;
  disabled: boolean;
  required: boolean;
  unique: boolean;
  checkType?: OPTION_GROUP_CHECK_TYPE;
}

export const SELECTED_EARS_TEXT: {[selected in SELECTED_EARS]: string} = {
  [SELECTED_EARS.NONE]: 'Aucune oreille',
  [SELECTED_EARS.LEFT_ONLY]: 'Oreille gauche',
  [SELECTED_EARS.RIGHT_ONLY]: 'Oreille droite',
  [SELECTED_EARS.BOTH]: 'Les deux oreilles',
};

export const getSelectedEars = ([left, right]: [
  boolean,
  boolean,
]): SELECTED_EARS =>
  left && right
    ? SELECTED_EARS.BOTH
    : left
    ? SELECTED_EARS.LEFT_ONLY
    : right
    ? SELECTED_EARS.RIGHT_ONLY
    : SELECTED_EARS.NONE;

export const getSelectedEarArray = ([left, right]: [boolean, boolean]): EAR[] =>
  left && right
    ? [EAR.LEFT, EAR.RIGHT]
    : left
    ? [EAR.LEFT]
    : right
    ? [EAR.RIGHT]
    : [];
