import {DatePicker, Modal} from 'antd';
import {PickerProps, SinglePickerProps} from 'antd/lib/date-picker/interface';
import moment from 'moment';
import React, {FC, memo, useState} from 'react';
import {useAsync} from 'react-async-hook';
import {useShippingDate} from '../../contexts/Quote';
import {useSession} from '../../contexts/Session';

const isoDay = 'YYYY-MM-DD';

interface DeliveryDateProps {
  delay?: number;
}

const DeliveryDate: FC<DeliveryDateProps> = ({delay = 0}) => {
  const session = useSession();
  const [modalVisiblity, setModalVisibility] = useState(false);
  const [shippingDate, setShippingDate] = useShippingDate();
  const shippingDateMoment = moment(shippingDate);

  const joursFermesAsync = useAsync(
    async () =>
      (await session.fetch<string[]>('Utils', 'getJoursFermes', {})) || [],
    [],
  );

  const delayedDate = moment().startOf('day');
  {
    let delayIterator = delay;
    while (delayIterator >= 0) {
      delayedDate.add(1, 'days');

      if (
        ![6, 7].includes(Number(delayedDate?.format('E') || '-1')) &&
        !(joursFermesAsync.result || []).includes(
          delayedDate?.format(isoDay) || 'invalid date',
        )
      ) {
        --delayIterator;
      }
    }
  }

  const isDateDisabled: PickerProps['disabledDate'] = (date) => {
    if (date?.isBefore(delayedDate)) {
      return true;
    }

    if ([6, 7].includes(Number(date?.format('E') || '-1'))) {
      return true;
    }

    if (
      (joursFermesAsync.result || []).includes(
        date?.format(isoDay) || 'invalid date',
      )
    ) {
      return true;
    }

    return false;
  };

  if (shippingDate && isDateDisabled(shippingDateMoment)) {
    setShippingDate(undefined);
  }

  const handleChange: SinglePickerProps['onChange'] = (date, dateString) => {
    if ((date?.format(isoDay) || 'invalid date') !== moment().format(isoDay)) {
      setModalVisibility(true);
    }

    setShippingDate(dateString);
  };

  return (
    <div style={{marginTop: 10}}>
      <h3 className="ant-form-item-required">Date de livraison souhaitée</h3>
      <DatePicker
        onChange={handleChange}
        value={shippingDateMoment.isValid() ? shippingDateMoment : null}
        disabledDate={isDateDisabled}
      />

      <Modal
        visible={modalVisiblity}
        title="Confirmation"
        cancelText="OK"
        onCancel={() => setModalVisibility(false)}
        okButtonProps={{style: {display: 'none'}}}
      >
        <p>Votre commande sera livrée le {shippingDateMoment.format('LL')}</p>
      </Modal>
    </div>
  );
};

export default memo(DeliveryDate);
