import React, {FC, memo} from 'react';
import {useHistory} from 'react-router-dom';
import {
  DeporteBrand,
  Product,
  ProductCategory,
  useCurrentBrand,
  useCurrentCategory,
  useCurrentProduct,
  useCurrentSelectedOptions,
} from '../../contexts/Quote';
import {productCategories} from './data';
import ProductSelection from './ProductSelection';

const ProductCategories: FC<{parentPath: string}> = ({parentPath}) => {
  const [category, setCategory] = useCurrentCategory();
  const [product, setProduct] = useCurrentProduct();
  const [, setBrand] = useCurrentBrand();
  const [, setSelectedOptions] = useCurrentSelectedOptions();
  const history = useHistory();

  const onSelect = (_category: ProductCategory): void => {
    if (_category !== category) {
      setSelectedOptions([]);
      setCategory(_category);

      if (product && !_category.products.includes(product)) {
        setProduct((undefined as unknown) as Product);
        setBrand((undefined as unknown) as DeporteBrand);
      }

      if (_category.products.length === 1) {
        setProduct(_category.products[0]);
        history.push(
          `${parentPath}/${_category.brands?.length ? 'brands' : 'options'}`,
        );
        return;
      }
    }

    history.push(`${parentPath}/products`);
  };

  return (
    <ProductSelection
      products={productCategories}
      onSelect={onSelect}
      value={category || undefined}
    />
  );
};

export default memo(ProductCategories);
