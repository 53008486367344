import {Alert, Card, Col, Form, Row, Spin} from 'antd';
import React, {FC, memo, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useRestore, useSession} from '../../contexts/Session';
import Logo from '../../Logo';
import {cssUtils} from '../../util/render';
import LoginForm, {LoginFormProps, LoginFormSubmitCallback} from './Form';

export interface LoginProps {
  callback?: string;
}

const Login: FC<LoginProps> = ({callback}) => {
  const session = useSession();
  const history = useHistory();
  const {token = localStorage.intersonToken} = useParams();

  if (token) {
    localStorage.intersonToken = token;
  }

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [attemptedTokenLogin, setAttemptedTokenLogin] = useState(false);

  const handleLoginAttempt = async (
    loginAttemptReuslt: boolean,
  ): Promise<void> => {
    setLoading(false);

    if (loginAttemptReuslt) {
      const params = new URLSearchParams(window.location.search);
      if (params.get('action') === 'viewOrder') {
        const ID = params.get('ID');
        if (ID) {
          history.push(`/order/${ID}`);
          return;
        }
      }

      history.push(callback || '/');
      return;
    }

    let healthStatus = 500;
    try {
      healthStatus = (
        await fetch(`${process.env.REACT_APP_WAKANDA_HOST}/health`)
      ).status;
    } finally {
      setError(
        healthStatus === 200
          ? "Votre nom d'utilisateur et/ou mot de passe sont invalides. Merci de ré-éssayer"
          : [
              'Connexion au serveur impossible.',
              'Merci vérifier votre connexion, ou de ré-éssayer dans quelques minutes',
            ].join(' '),
      );
    }
  };

  if (token && !attemptedTokenLogin) {
    setAttemptedTokenLogin(true);
    setLoading(true);
    console.log('attempting token login', {
      attemptedTokenLogin,
      token,
    });

    (async () => {
      const [username, password] = [token.substr(0, 40), token.substr(40, 40)];
      handleLoginAttempt(await session.login(username, password));
    })();
  }

  const WrappedLoginForm = Form.create<LoginFormProps>({
    name: 'loginForm',
  })((props) => <LoginForm {...props} loading={loading} />);

  const submit: LoginFormSubmitCallback = async ({
    username,
    password,
    remember,
  }) => {
    setError('');
    setLoading(true);

    handleLoginAttempt(await session.login(username, password, remember));
  };

  return (
    <Row type="flex" justify="center" align="middle" css={cssUtils.heightP()}>
      <Col span={20} xl={8}>
        <Row
          type="flex"
          justify="center"
          align="middle"
          css={{backgroundColor: 'white', padding: '20px 0', marginBottom: 20}}
        >
          <Col>
            <Logo />
          </Col>
        </Row>
        <Row>
          {error ? (
            <Alert
              message="Erreur de connexion"
              description={error}
              type="error"
              closable
              css={{marginBottom: 20}}
            />
          ) : null}
          <Card
            title={`Connexion à ${process.env.REACT_APP_HEAD_TITLE}`}
            css={{width: '100%'}}
          >
            <WrappedLoginForm onSubmit={submit} />
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default memo(Login);
