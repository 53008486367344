import {css} from '@emotion/core';
import {Col, Layout, Row} from 'antd';
import React, {FC, memo} from 'react';
import {useLocation} from 'react-router-dom';
import {useUser} from './contexts/Session';

const {Footer: AntFooter} = Layout;

const footerCss = css`
  background-color: white;
  border-top: solid 1px #ccc;
  padding: 12px 50px;

  p {
    margin-bottom: 3px;
  }
`;

const baseUrl = 'https://espace-pro.interson-protac.com';

const Footer: FC = () => {
  const user = useUser();
  useLocation();

  let authQuery = '';
  if (user) {
    authQuery = `?auth=${user.hash}`;
  }

  return (
    <AntFooter css={footerCss}>
      <Row type="flex" justify="center" gutter={30}>
        <Col>
          <img src="/logo_footer.png" alt="Logo" css={{marginTop: '10px'}} />
        </Col>
        <Col>
          <h3>Suivi</h3>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/orders${authQuery}`}
            >
              Suivi des éléments
            </a>
          </p>
        </Col>
        <Col>
          <h3>Gestion</h3>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/request/invoice${authQuery}`}
            >
              Factures
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/request/delivery${authQuery}`}
            >
              Bon de livraison
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/request/order${authQuery}`}
            >
              Bon de commande
            </a>
          </p>
        </Col>
        <Col>
          <h3>Téléchargements</h3>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/sales${authQuery}`}
            >
              Promotion et info du mois
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://kiosque-interson.com/Catalogue2022/A/"
            >
              Catalogue 2022
            </a>
          </p>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/technical-documents${authQuery}`}
            >
              Fiche technique &amp; brochure
            </a>
          </p>
        </Col>
        <Col>
          <h3>Commander</h3>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${baseUrl}/stores${authQuery}`}
            >
              IP3D
            </a>
          </p>
        </Col>
        <Col>
          <ul>
            <h3>Contact</h3>
            <p>
              <a href={`${baseUrl}/contact${authQuery}`}>
                Contacter un service interne
              </a>
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/assets/cgv.pdf`}
              >
                Conditions générales de vente
              </a>
            </p>
          </ul>
        </Col>
      </Row>
    </AntFooter>
  );
};

export default memo(Footer);
