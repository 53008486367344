import {css} from '@emotion/core';
import {Descriptions} from 'antd';
import React, {FC, memo, ReactElement, ReactNode} from 'react';
import {
  Options,
  UndefinedTuple,
  useCurrentLockedEars,
} from '../../contexts/Quote';
import {SELECTED_EARS_EMOJIS} from '../../util/render';
import {getTypeLabel, TYPE, colorLabels, COLOR} from '../Options/data';
import {getSelectedEars, SELECTED_EARS} from '../Options/static';

export interface OptionDescriptionProps {
  nocss?: boolean;
  title?: string | ReactElement;
  options?: Options;
  descriptionSpan?: number;
  showSelectedEars?: boolean;
}

type BasicEarOption = {
  label: string;
  type: TYPE;
  value?: UndefinedTuple<number | string>;
};
type EarsOptionsMap = {[selected in SELECTED_EARS]: BasicEarOption[]};

const getEmptyEarOptionsMap = (): EarsOptionsMap =>
  (Object.fromEntries(
    Object.values(SELECTED_EARS).map((selected) => [selected, []]),
  ) as unknown) as EarsOptionsMap;

const OptionDescription: FC<OptionDescriptionProps> = ({
  nocss = false,
  descriptionSpan = 1,
  options = null,
  title = 'Options',
  showSelectedEars = true,
}) => {
  const [lockedEars] = useCurrentLockedEars();

  if (!options) {
    return null;
  }

  const selectedEars = getSelectedEars(options.ears);
  const earOptions = options.selected.reduce<EarsOptionsMap>(
    (res, {selected, label, type, value}) => {
      const ears = getSelectedEars(selected);
      res[ears].push({label, type, value: value});

      return res;
    },
    getEmptyEarOptionsMap(),
  );

  const heightCSS = nocss
    ? null
    : css`
        @media (max-width: 1600px) {
          .ant-descriptions-view {
            height: 100px;
            overflow-y: auto;
          }
        }
      `;

  return (
    <Descriptions
      title={title}
      layout="horizontal"
      size="small"
      css={heightCSS}
    >
      {showSelectedEars ? (
        <Descriptions.Item label="Oreilles" span={descriptionSpan}>
          {SELECTED_EARS_EMOJIS[selectedEars]}
        </Descriptions.Item>
      ) : null}
      {(Object.entries(earOptions) as [SELECTED_EARS, BasicEarOption[]][]).map<
        ReactNode
      >(([selected, options], index) =>
        options.length ? (
          <Descriptions.Item
            label={
              selectedEars === SELECTED_EARS.BOTH && !lockedEars
                ? SELECTED_EARS_EMOJIS[selected]
                : 'Options'
            }
            span={descriptionSpan}
            key={index}
          >
            {(options || []).map(({label, type, value}, index) => (
              <span key={index}>
                <span css={{cursor: 'help'}} title={getTypeLabel(type)}>
                  {label}{' '}
                  {value
                    ? `(${value
                        .map((val) =>
                          val && typeof val === 'string'
                            ? type === TYPE.TUBE && colorLabels[val as COLOR]
                              ? colorLabels[val as COLOR]
                              : val
                            : val && typeof val === 'number'
                            ? `${val.toFixed(1).replace(/\./g, ',')} mm`
                            : '',
                        )
                        .join(selected === SELECTED_EARS.BOTH ? ' et ' : '')})`
                    : ''}
                </span>
                {index < options.length - 1 ? ', ' : ''}
              </span>
            ))}
          </Descriptions.Item>
        ) : null,
      )}
    </Descriptions>
  );
};

export default memo(OptionDescription);
