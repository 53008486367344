import {css} from '@emotion/core';
import {Col, Form} from 'antd';
import {ColProps} from 'antd/es/col';
import {FormComponentProps} from 'antd/lib/form/Form';
import React, {FC, memo} from 'react';
import {BaseQuoteData} from '../../contexts/Quote';
import {User, UserType} from '../../util/fetch-wakanda';
import ShippingAddressSelect from '../Quote/patient/ShippingAddressSelect';
import TransporterSelect from '../Quote/patient/TransporterSelect';

type ShipmentFormData = Pick<BaseQuoteData, 'shippingAddress' | 'transporter'>;

const formCss = css`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

interface ShipmentFormProps extends FormComponentProps<ShipmentFormData> {
  user: User;
  quote: BaseQuoteData;
  onSubmit?: (data: Partial<BaseQuoteData>) => void;
}

const columnProps: Partial<ColProps> = {
  span: 24,
};

const ShipmentForm: FC<ShipmentFormProps> = ({form, user, quote, onSubmit}) => {
  const {getFieldDecorator, validateFieldsAndScroll} = form;

  const {clientId = user.ID} = quote;

  const handleSubmit = (): void => {
    validateFieldsAndScroll((err, values) => {
      if (err) {
        return;
      }

      onSubmit?.(values);
    });
  };

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
      css={formCss}
    >
      <Col
        {...columnProps}
        css={{display: user.type === UserType.CLIENT ? 'none' : ''}}
      >
        <Form.Item label="Adresse de livraison">
          {getFieldDecorator('shippingAddress', {
            initialValue: quote?.shippingAddress,
          })(
            <ShippingAddressSelect
              placeholder="Adresse existante ou nouvelle"
              clientId={clientId}
              onChange={(shippingAddress) => {
                form.setFieldsValue({
                  shippingAddress,
                });
                handleSubmit();
              }}
            />,
          )}
        </Form.Item>
      </Col>
      <Col {...columnProps}>
        <Form.Item label="Transporteur">
          {getFieldDecorator('transporter', {
            initialValue: quote?.transporter,
          })(
            <TransporterSelect
              placeholder="Méthode de livraison"
              clientId={clientId}
              onChange={(transporter) => {
                form.setFieldsValue({
                  transporter,
                });
                handleSubmit();
              }}
            />,
          )}
        </Form.Item>
      </Col>
    </Form>
  );
};

export default memo(ShipmentForm);
